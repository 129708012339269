import { forwardRef, useEffect, useState } from "react";
import { Dialog, DialogContent, Slide } from "@mui/material";
import { CrossIcon, WarningInfoIcon } from "assets";
import FileUpload from "component/FileUpload";
import { createCandidates } from "services/Job";
import InfoDialog from "./InfoDialog";
import { handleDownloadSampleCsv } from "utils/common";
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UploadCandidates({
  open,
  setOpen,
  jobId,
  handleCallBack,
}) {
  const [loading, setLoading] = useState(false);
  const [uploadedCandidates, setUploadedCandidates] = useState([]);
  const [fileInfo, setFileInfo] = useState({ name: "", size: 0 });
  const [validationErrors, setValidationErrors] = useState({
    fileInfo: "",
  });
  const [infoDialog, setInfoDialog] = useState(false);
  const [duplicates, setDuplicates] = useState(0);

  const errorMsg = {
    fileInfo: "CSV or Excel file must be uploaded",
  };
  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  const validateSubmit = () => {
    const errors = {};
    if (!fileInfo.name) {
      errors.fileInfo = errorMsg.fileInfo;
    }
    return errors;
  };

  useEffect(() => {
    if (open) {
      resetState();
    }
  }, [open]);

  const resetState = () => {
    setLoading(false);
    setUploadedCandidates([]);
    setFileInfo({ name: "", size: 0 });
    setValidationErrors({ fileInfo: "" });
  };

  const handleSubmit = async () => {
    let errors = validateSubmit();

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      setValidationErrors({});
      if (uploadedCandidates.length > 0) {
        setLoading(true);
        const result = await createCandidates(jobId, uploadedCandidates);
        setLoading(true);
        setOpen(false);
        handleCallBack();
        if (result?.data?.result?.skipped) {
          setInfoDialog(true);
          setDuplicates(result?.data?.result?.skipped);
        }
      }
    }
  };
  return (
    <>
      {infoDialog && duplicates && (
        <InfoDialog
          open={infoDialog}
          setOpen={setInfoDialog}
          infoDetail={{
            heading: `We have Detected and Removed ${duplicates} Duplicates`,
            innerContent: "",
          }}
          handleSubmit={() => {}}
          icon={WarningInfoIcon}
        />
      )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        PaperProps={{
          style: {
            maxWidth: "896px",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent className="relative font-hankengrotesk scrollbar !py-[52px] lg:!px-[197px]">
          <div
            className="absolute top-4 right-4 cursor-pointer"
            onClick={handleClose}
          >
            <img src={CrossIcon} alt="crossIcon" />
          </div>
          <div className="rounded-md max-w-[500px] m-auto">
            <div className="font-semibold text-[28px] text-center">
              Upload Candidates
            </div>

            <div className="mb-[52px] mt-[24px] flex flex-col">
              <div className="flex flex-col gap-5">
                <div className="flex flex-col gap-1">
                  <h2 className="text-[rgb(31,31,31)] text-base leading-[22px] font-semibold">
                    Upload CSV
                  </h2>
                  <h3 className="text-[rgb(31,31,31)] text-[13px] leading-5 font-light tracking-[-0.01em]">
                    Please upload a CSV file with the candidates names and
                    contact numbers.{" "}
                    <span
                      className="text-blue-500 cursor-pointer"
                      onClick={handleDownloadSampleCsv}
                    >
                      Click here
                    </span>{" "}
                    to see a template of what it should look like.
                  </h3>
                </div>
                <div className="flex flex-col gap-3">
                  <FileUpload
                    fileInfo={fileInfo}
                    setFileInfo={(file) => {
                      setFileInfo(file);
                      // Clear file validation error if file is uploaded
                      if (file && file.name) {
                        setValidationErrors((prevErrors) => ({
                          ...prevErrors,
                          fileInfo: null, // Clear file error
                        }));
                      }
                    }}
                    setUploadedCandidates={setUploadedCandidates}
                  />
                  {validationErrors.fileInfo && (
                    <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
                      {validationErrors.fileInfo}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="w-full flex justify-between items-center gap-3 sm:gap-0">
              <button
                className={`btn h-[50px] bg-white flex items-center border-custom-primary border text-black border-purple font-semibold lg:py-4 py-3 lg:px-12 px-7 rounded-lg`}
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>
              <button
                className="btn h-[50px] flex items-center bg-custom-primary text-white font-semibold lg:py-4 py-3 lg:px-12 px-7 rounded-lg  sm:ml-4 disabled:opacity-75"
                onClick={() => handleSubmit()}
                disabled={loading}
              >
                {loading ? "Uploading..." : "Upload"}
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
