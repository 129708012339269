import React, { useContext, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { loginService } from "services/Auth";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import AuthContext from "contexts/authContext";
import { Paths } from "routes";
import ForgotPasswordVerifyEmail from "dialogs/ForgotPassVerifyEmail";
import { EyeIcon, EyeOffIcon } from "assets";

function Login() {
  const [loader, setLoader] = useState(false);
  const { changeAuth } = useContext(AuthContext);
  const [forgotPasswordPopup, setForgotPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="w-full sm:max-w-[420px] max-w-full mx-auto md:px-0 px-4 font-hankengrotesk">
      <ForgotPasswordVerifyEmail open={forgotPasswordPopup} setOpen={setForgotPassword} />

      <h4 className="my-7 text-[#2e2e2e] text-2xl text-center font-medium leading-7 -tracking-[0.24px]">
        Login
      </h4>
      {/* <div className="flex flex-col"> */}
      <Formik
        initialValues={{
          email: _.get(location, "state.email", ""),
          password: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setLoader(true);
            const userData = await loginService({
              Email: values.email,
              Password: values.password,
            });

            if (userData) {
              changeAuth({ isLoggedIn: true, userData: userData.data });
              setStatus({ success: true });
              setSubmitting(false);
              navigate({
                pathname: "/",
                state: {
                  isRedirectFromRegister: false,
                  isRedirectFromLogin: true,
                },
              });
              try {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  UserId: userData.data.userId,
                });
              } catch (err) {
                console.log("error in data layer");
              }
            } else {
              setStatus({ success: false });
              setErrors({
                submit:
                  "Email or password is invalid, please check and try again",
              });
            }
          } catch (err) {
            console.error(err);
            setStatus({ success: false });
            if (
              _.get(err, "response.data.code", "") ===
              "otp_verification_required"
            ) {
              //   navigate(Paths.otpVerifying);
            }
            setErrors({
              submit: _.get(err, "response.data.title", "Something went wrong"),
            });
            setSubmitting(false);
          } finally {
            setLoader(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          validateField,
          setFieldValue,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <div className="flex flex-col gap-[18px]">
              <div className="flex flex-col gap-1">
                <label className="text-[#1F1F1F] text-[13px] fo font-normal leading-5 -tracking-[0.13px] ">
                  Work Email Address
                </label>
                <input
                  name="email"
                  placeholder="Email Address"
                  onBlur={async (e) => {
                    const trimmedEmail = e.target.value.trim();
                    await setFieldValue("email", trimmedEmail); 
                    validateField("email"); // Manually trigger validation after setting the value
                    handleBlur(e);
                  }}
                  onChange={handleChange}
                  value={values.email}
                  className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full outline-none"
                />
                {Boolean(touched.email && errors.email) && (
                  <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A] ">
                    {touched.email && errors.email}
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-1 relative">
                <label className="text-[#1F1F1F] text-[13px] font-normal leading-5 -tracking-[0.13px] ">
                  Password
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full outline-none"
                />
                 <span
                  onClick={() => setShowPassword(!showPassword)}
                  className={`absolute right-4 top-1/2 cursor-pointer -translate-y-1/2`}
                >
                  <img src={showPassword ? EyeOffIcon : EyeIcon} alt="eye" />
                </span>
                <div className="flex justify-between items-center gap-2">
                  {Boolean(touched.password && errors.password) && (
                    <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A] ">
                      {touched.password && errors.password}
                    </div>
                  )}
                  <span
                    className="text-[#121212] text-[14px] leading-5 font-semibold cursor-pointer text-right ml-auto block"
                    onClick={() => setForgotPassword(true)}
                    >
                      Forgot password ?
                  </span>
                </div>
              </div>
            </div>

            <div className="w-full flex flex-col justify-center items-center">
              {errors.submit && (
                <div className="w-full text-sm font-medium text-[#FF352A] mt-[15px] mb-3">
                  {errors.submit}
                </div>
              )}
              <button
                disabled={loader}
                className="btn bg-custom-primary text-white font-semibold py-4 px-12 rounded-lg w-full sm:w-auto m-auto mt-11 mb-8 disabled:opacity-75 "
              >
                {loader ? "Log in..." : "Log in"}
              </button>
              {/* <span
                className="text-[#121212] text-[14px] leading-5 font-semibold pb-12 cursor-pointer"
                onClick={() => navigate(Paths.SignUp)}
              >
                Not a member yet?
              </span> */}
            </div>
          </form>
        )}
      </Formik>
      {/* </div> */}
    </div>
  );
}

export default Login;
