import { Navigate, createBrowserRouter, Outlet } from "react-router-dom";
import Navbar from "component/Navbar";
import GuestNavbar from "component/GuestNavbar";
import Sidebar from "component/Sidebar";
import LandingPage from "pages/LandingPage";
import OverView from "pages/Overview";
import SignUpPageV2 from "pages/SignUp/indexv2";
import LoginPage from "pages/Login";
import Feedback from "pages/Feedback";
import MyAccount from "pages/MyAccount";
import ContactUs from "pages/ContactUs";
import JobDetail from "pages/Overview/jobDetail";
import { GuestPageBgImage } from "assets";
import AdminPortal from "pages/AdminPortal";

export const Paths = {
  SignUp: "/sign-up",
  Login: "/login",
  Feedback: "/feedback",
  ContactUs: "/contact-us",
  MyAccount: "/my-account",
  Logout: "/logout",
  AdminPortal: "/admin-portal"
};

export const router = (auth = {}, isSidebarOpen, setIsSidebarOpen) => {
  let routes = [];
  
  routes.push({
    path: Paths.ContactUs,
    element: <ContactUs />,
  });

  const isLoggedIn = localStorage.getItem("token");
  if (isLoggedIn) {
    routes.push({
      path: "/",
      element: <Layout auth={auth} isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />,
      children: [
        {
          path: "/",
          element: <OverView auth={auth}/>,
        },
        {
          path: "/:jobId",
          element: <JobDetail auth={auth}/>
        },
        {
          path: "/:jobId/ai-chat",
          element: <JobDetail auth={auth}/>
        },
        {
          path: "/:jobId/ai-chat/:chatId",
          element: <JobDetail auth={auth}/>
        },
        {
          path: Paths.Feedback,
          element: <Feedback />,
        },
        {
          path: Paths.AdminPortal,
          element: <AdminPortal auth={auth}/>,
        },
        {
          path: Paths.MyAccount,
          element: <MyAccount auth={auth}/>,
        },
        {
          path: "*",
          element: <Navigate to="/" />,
        },
      ],
    });
  } else {
    routes.push(
      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path: "/:section",
        element: <LandingPage />,
      },
      {
        path: "/",
        element: <GuestLayout />,
        children: [
          {
            path: Paths.SignUp,
            element: <SignUpPageV2 />,
          },
          {
            path: Paths.Login.replace("/", ""),
            element: <LoginPage />,
          },
        ],
      },
      {
        path: "*",
        element: <Navigate to="/" />,
      }
    );
  }

  return createBrowserRouter(routes);
};

// Layout Component with Navbar and LeftSidebar
const Layout = ({auth, isSidebarOpen, setIsSidebarOpen}) => {
  return (
  <div className="flex h-full font-hankengrotesk">
      <Sidebar auth={auth} isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} /> {/* Sidebar on the side */}
      <div className={`flex-1 flex flex-col h-full md:w-[calc(100%_-_232px)] ${isSidebarOpen ? "w-[calc(100%_-_232px)]" : "w-full"}`}>
        <Navbar auth={auth} />
        <Outlet /> {/* This renders the matched child route's component */}
      </div>
    </div>
  );
};

// GuestLayout showing when user not logged in
const GuestLayout = () => {
  return (
    <div className="flex flex-col h-full font-hankengrotesk overflow-auto scrollbar">
      <GuestNavbar />
      <main style={{
    // background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.92) 0%, rgba(255, 255, 255, 0.92) 100%), #2764D2',
    backgroundImage: `url(${GuestPageBgImage})`,
    backgroundSize: "cover"
  }} className="w-full flex flex-col flex-1 pt-12 pb-20">
        <div className="sm:w-full w-auto max-w-full md:max-w-[659px] sm:max-w-[600px] bg-white sm:mx-auto mx-3  rounded-xl">
          <Outlet /> {/* This renders the matched child route's component */}
        </div>
      </main>
    </div>
  );
};
