import { forwardRef, useState } from "react";
import { Dialog, DialogContent, Slide } from "@mui/material";
import { CrossIcon } from "assets";
import SalaryRangeSlider from "component/SalaryRangeSlider";
import FileUpload from "component/FileUpload";
import Stepper from "component/Stepper";
import { handleDownloadSampleCsv } from "utils/common";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewJob = (props) => {
  const {
    open,
    setOpen,
    handleCreateJob,
    mode = "add",
    jobData,
    editState,
  } = props;

  const jobCreationSteps = ["Job Details", "Candidates", "Call Details"];

  const [step, setStep] = useState(editState === "edit_call" ? 2 : 0);
  const [jobDetail, setJobDetail] = useState({
    title: jobData?.title || "",
    workplaceType: jobData?.workspaceType || "on-site",
    expectedExperience: jobData?.expectedExperience || 1,
    location: jobData?.location || "",
    annualSalary:
      jobData?.minSalary && jobData?.maxSalary
        ? [jobData?.minSalary, jobData?.maxSalary]
        : [80000, 120000],
    prompt: jobData?.prompt || "",
  });
  const [fileInfo, setFileInfo] = useState({ name: "", size: 0 });
  const [uploadedCandidates, setUploadedCandidates] = useState([]);
  const [manualDetail, setManualDetail] = useState("");
  const [isManual, setIsManual] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  // New state to handle validation errors
  const [validationErrors, setValidationErrors] = useState({
    title: "",
    location: "",
    manualDetail: "",
    fileInfo: "",
    prompt: "",
  });

  const errorMsg = {
    title: "Job Title is required",
    location: "Location is required",
    manualDetail: "Manual detail is required",
    fileInfo: "CSV or Excel file must be uploaded",
    prompt: "Promt detail is required",
  };

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  const handleChangeJobDetails = (field, value) => {
    setJobDetail((prev) => ({
      ...prev,
      [field]: value,
    }));

    // Clear validation errors dynamically when user types
    // if (validationErrors[field]) {
    //   setValidationErrors((prev) => ({
    //     ...prev,
    //     [field]: "",
    //   }));
    // }
    if (field !== "prompt") {
      setValidationErrors((prev) => ({
        ...prev,
        [field]: value ? "" : errorMsg[field],
      }));
    }
  };

  const handleToggleChange = () => {
    setIsManual(!isManual);
    // Clear validation errors when toggle is switched
    setValidationErrors((prev) => ({
      ...prev,
      fileInfo: "",
      manualDetail: "",
    }));
  };

  const validateStep1 = () => {
    const errors = {};
    if (!jobDetail.title) {
      errors.title = errorMsg.title;
    }
    if (!jobDetail.location) {
      errors.location = errorMsg.location;
    }
    return errors;
  };

  const validateStep2 = () => {
    const errors = {};
    if (isManual) {
      if (!manualDetail) {
        errors.manualDetail = errorMsg.manualDetail;
      }
    } else {
      if (!fileInfo.name) {
        errors.fileInfo = errorMsg.fileInfo;
      }
    }
    return errors;
  };

  const validateStep3 = () => {
    const errors = {};
    if (!jobDetail.prompt) {
      errors.prompt = errorMsg.prompt;
    }
    return errors;
  };

  const handleNextStep = () => {
    let errors = {};
    if (step === 0) {
      errors = validateStep1();
    } else if (step === 1) {
      errors = validateStep2();
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      setValidationErrors({});
      setStep(step + 1); // Move to the next step if no errors
    }
  };

  const renderStep1Content = () => {
    return (
      <>
        <div className="flex flex-col gap-[6px] lg:mb-4 mb-3">
          <label
            htmlFor="jobtitle"
            className="text-[15px] leading-5 font-normal -tracking-[1%] text-[#1f1f1f]"
          >
            Title of the job you're hiring for
          </label>
          <input
            value={jobDetail.title}
            id="jobtitle"
            type="text"
            placeholder="Ex Project Manager"
            className="input text-gray-700 bg-white disabled:bg-[#e5e5e599] border border-gray-300 rounded-lg py-[14px] leading-5 h-12 px-4 w-full outline-none"
            onChange={(event) =>
              handleChangeJobDetails("title", event.target.value)
            }
            disabled={mode === "edit"}
          />
          {validationErrors.title && (
            <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
              {validationErrors.title}
            </span>
          )}
        </div>

        <div className="flex lg:flex-row flex-col gap-5 mb-4">
          <div className="flex flex-col gap-[6px] flex-1">
            <label
              htmlFor="large"
              className="text-[15px] leading-5 font-normal text-[#1f1f1f] "
            >
              Workplace Type
            </label>
            <select
              id="large"
              className="w-full h-12 pl-[16px] pr-[14px] py-[14px] text-base text-gray-900 border border-[#E5E5E5] rounded-lg focus-visible:outline-none"
              onChange={(event) =>
                handleChangeJobDetails("workplaceType", event.target.value)
              }
              value={jobDetail.workplaceType}
            >
              <option value="office">Office</option>
              <option value="remote">Remote</option>
              <option value="hybrid">Hybrid</option>
            </select>
          </div>

          <div className="flex flex-col gap-[6px] flex-1">
            <label
              htmlFor="expectedExperience"
              className="text-[15px] leading-5 font-normal -tracking-[1%] text-[#1f1f1f] "
            >
              Expected Experience
            </label>
            <input
              value={jobDetail.expectedExperience}
              id="expectedExperience"
              type="number"
              min={0}
              className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-[14px] h-12 px-4 w-full outline-none"
              onChange={(event) =>
                handleChangeJobDetails("expectedExperience", event.target.value)
              }
            />
          </div>
        </div>

        <div className="flex flex-col gap-[6px] lg:mb-4 mb-3">
          <label
            htmlFor="location"
            className="text-[15px] leading-5 font-normal -tracking-[1%] text-[#1f1f1f] "
          >
            Location
          </label>
          <input
            value={jobDetail.location}
            id="location"
            type="text"
            placeholder="Ex London, UK"
            className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-[14px] h-12 px-4 w-full outline-none"
            onChange={(event) =>
              handleChangeJobDetails("location", event.target.value)
            }
          />
          {validationErrors.location && (
            <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
              {validationErrors.location}
            </span>
          )}
        </div>

        <SalaryRangeSlider
          value={jobDetail.annualSalary}
          setValue={(value) => handleChangeJobDetails("annualSalary", value)}
        />
      </>
    );
  };

  const renderStep2Content = () => {
    return (
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-1">
          <h2 className="text-[rgb(31,31,31)] text-base leading-[22px] font-semibold">
            Upload CSV
          </h2>
          <h3 className="text-[rgb(31,31,31)] text-[13px] leading-5 font-light tracking-[-0.01em]">
            Please upload a CSV file with the candidates names and contact
            numbers.{" "}
            <span
              className="text-blue-500 cursor-pointer"
              onClick={handleDownloadSampleCsv}
            >
              Click here
            </span>{" "}
            to see a template of what it should look like.
          </h3>
        </div>
        <div className="flex flex-col gap-3">
          {/* <div className="flex items-center gap-[10px]">
            <label
              htmlFor="toggle"
              className="text-[#121212] opacity-70 text-[13px] leading-4 font-semibold"
            >
              Paste Manually
            </label>
            <input
              type="checkbox"
              id="toggle"
              className="toggle-checkbox hidden"
              checked={isManual}
              onChange={handleToggleChange}
            />
            <div
              className={`relative w-10 h-5 rounded-full flex items-center transition duration-200 ease-in select-none ${
                isManual ? "bg-blue-600" : "bg-gray-300"
              }`}
              onClick={handleToggleChange}
            >
              <div
                className={`w-4 h-4 rounded-full bg-white cursor-pointer transition duration-200 ease-in ${
                  isManual ? "translate-x-[22px]" : "translate-x-[2px]"
                }`}
              ></div>
            </div>
          </div> */}

          {isManual ? (
            <>
              <textarea
                id="manualDetail"
                name="manualDetail"
                rows="8"
                placeholder="Ex John Doe: 065 583 9723, Robert Fox: 062 531 3135"
                onChange={(event) => {
                  setManualDetail(event.target.value);
                  setValidationErrors((prev) => ({
                    ...prev,
                    manualDetail: event.target.value
                      ? ""
                      : errorMsg.manualDetail,
                  }));
                }}
                className="input text-gray-700 bg-white border border-gray-300 rounded-lg max-h-[220px] h-full py-[14px] px-4 w-full outline-none resize-none"
                value={manualDetail}
              />
              {validationErrors.manualDetail && (
                <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
                  {validationErrors.manualDetail}
                </span>
              )}
            </>
          ) : (
            <>
              <FileUpload
                fileInfo={fileInfo}
                setFileInfo={(file) => {
                  setFileInfo(file);
                  // Clear file validation error if file is uploaded
                  if (file && file.name) {
                    setValidationErrors((prevErrors) => ({
                      ...prevErrors,
                      fileInfo: null, // Clear file error
                    }));
                  }
                }}
                setUploadedCandidates={setUploadedCandidates}
              />
              {validationErrors.fileInfo && (
                <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
                  {validationErrors.fileInfo}
                </span>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const renderStep3Content = () => {
    return (
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-1">
          <h2 className="text-base text-[#1F1F1F] leading-[22px] -tracking-[1%] font-medium">
            Train our AI agent
          </h2>
          <h3 className="text-[#1F1F1F] text-[13px] leading-5 -tracking-[1%] font-light">
            Use this space for additional context for your AI agent. It will
            help them tailor the conversation with the details you want. You can
            write multiple prompts.
          </h3>
        </div>
        <div className="flex flex-col gap-[6px]">
          <label className="text-[#1F1F1F] text-[13px] font-normal leading-5 -tracking-[0.13px]">
            Prompt Details for a Call
          </label>
          <textarea
            name="Prompt Details for a Call"
            rows="7"
            placeholder={`Ex "Make sure you ask about notice period"`}
            value={jobDetail.prompt || ""}
            onChange={(event) => {
              handleChangeJobDetails("prompt", event.target.value);
            }}
            className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full outline-none resize-none"
          />
          {validationErrors.prompt && (
            <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
              {validationErrors.prompt}
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleSubmit = () => {
    setValidationErrors({});
    handleCreateJob({
      title: jobDetail.title,
      workspaceType: jobDetail.workplaceType,
      expectedExperience: jobDetail.expectedExperience,
      location: jobDetail.location,
      minSalary: jobDetail.annualSalary[0],
      maxSalary: jobDetail.annualSalary[1],
      status: "active",
      prompt: jobDetail.prompt,
      candidates: uploadedCandidates,
    });
  };

  const handleEditSubmit = async () => {
    let errors = {};
    if (editState === "edit_job_details") {
      errors = validateStep1();
    }
    // else if (editState === "edit_call") {
    //   errors = validateStep3()
    // }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setSubmitLoading(true);
    setValidationErrors({});
    await handleCreateJob(
      editState === "edit_job_details"
        ? {
            title: jobDetail.title,
            workspaceType: jobDetail.workplaceType,
            expectedExperience: jobDetail.expectedExperience,
            location: jobDetail.location,
            minSalary: jobDetail.annualSalary[0],
            maxSalary: jobDetail.annualSalary[1],
            prompt: jobDetail.prompt,
          }
        : {
            title: jobDetail.title,
            workspaceType: jobDetail.workplaceType,
            expectedExperience: jobDetail.expectedExperience,
            location: jobDetail.location,
            minSalary: jobDetail.annualSalary[0],
            maxSalary: jobDetail.annualSalary[1],
            prompt: jobDetail.prompt,
          }
    );
    setSubmitLoading(false);
  };
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        PaperProps={{
          style: {
            maxWidth: "896px",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent className="relative font-hankengrotesk scrollbar !py-[42px] lg:!px-[197px]">
          <div
            className="absolute top-4 right-4 cursor-pointer"
            onClick={handleClose}
          >
            <img src={CrossIcon} alt="crossIcon" />
          </div>
          <div className="rounded-md max-w-[500px] m-auto">
            <div
              className={`font-semibold text-[28px] leading-9 text-center ${
                mode === "edit" ? "mb-6" : ""
              }`}
            >
              {step === 0
                ? "New Job"
                : step === 1
                ? "Upload Candidates"
                : "Call Details"}
            </div>

            {mode === "add" && (
              <div className="mt-6 mb-8">
                <Stepper selectedStep={step} steps={jobCreationSteps} />
              </div>
            )}

            <div className="xl:mb-[52px] lg:mb-9 md:mb-7 mb-4 flex flex-col">
              {step === 0
                ? renderStep1Content()
                : step === 1
                ? renderStep2Content()
                : step === 2
                ? renderStep3Content()
                : null}
            </div>

            <div className="w-full flex justify-between items-center gap-3 sm:gap-0">
              <button
                className={`btn h-[50px] bg-white flex items-center border-custom-primary border-[1px] text-black border-purple font-semibold lg:py-4 py-3 lg:px-12 px-7 rounded-lg  ${
                  step === 0 ? "opacity-40 cursor-not-allowed" : ""
                }`}
                onClick={() => {
                  if (step > 0) {
                    setStep(step - 1);
                    setValidationErrors({});
                  }
                }}
                disabled={step === 0}
              >
                {mode === "edit" ? "Cancel" : "Back"}
              </button>
              <button
                className="btn h-[50px] flex items-center bg-custom-primary text-white font-semibold lg:py-4 py-3 lg:px-12 px-7 rounded-lg  sm:ml-4 disabled:opacity-75"
                onClick={() =>
                  mode === "edit"
                    ? handleEditSubmit()
                    : step === 2
                    ? handleSubmit()
                    : handleNextStep()
                }
                disabled={submitLoading}
              >
                {submitLoading
                  ? "Submitting..."
                  : step === 2 || mode === "edit"
                  ? "Submit"
                  : "Next"}
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NewJob;
