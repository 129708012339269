const URI = Object.freeze(
  "https://0pmu96psye.execute-api.eu-west-1.amazonaws.com/dev/v1"
);
// const URI = Object.freeze("http://localhost:7000/v1");
export const APIURI = URI;

export const endPoints = Object.freeze({
  auth: {
    signup: URI + "/auth/signup",
    login: URI + "/auth/login",
    getUser: URI + "/auth/getUser",
    updateCompanyName: URI + "/auth/change-company-name",
    changePassword: URI + "/auth/change-password",
    updatePassword: URI + "/auth/update-password",
    sendResetPasswordOtp: URI + "/auth/send-reset-password-otp",
    sendOtp: URI + "/auth/sendOtp",
    validateOtp: URI + "/auth/validate-otp",
    deleteAccount: URI + "/auth/delete-account"
  },
  subscription: {
    fetchSubscription: URI + "/subscription",
    createCheckoutSession: URI + "/subscription/create-checkout-session",
  },
  job: {
    getJobs: URI + "/job",
    getJobDetail: URI + "/job/:job_id",
    createJob: URI + "/job",
    updateJob: URI + "/job/:jobId",
    updateJobStatus: URI + "/job/:jobId/status",
    getJob:  URI + "/job/:jobId",
    getCandidates: URI + "/job/:job_id/candidates",
    getCalls: URI + "/job/:job_id/calls",
    createCandidates: URI + "/job/:job_id/candidates",
    createCandidate: URI + "/job/:job_id/candidate",
    updateCandidate: URI + "/job/:job_id/candidate/:candidate_id/category",
    deleteCandidate: URI + "/job/:job_id/candidate/:candidate_id",
    updateCandidateNotes: URI + "/job/:job_id/candidate/:candidate_id/notes",
    executeAICall: URI + "/job/:job_id/candidate/:candidate_id/dial-call",
    cancelSingleCall: URI + "/job/:job_id/candidate/:candidate_id/cancel-call",
    cancelAllCalls: URI + "/job/:job_id/cancel-all-calls",
    dialAllCalls: URI + "/job/:job_id/dial-all-calls"
  },
  AIChat: {
    createChat: URI + "/chats/job/:jobId",
    getAllChat: URI + "/chats/job/:jobId",
    updateChatTitle: URI + "/chats",
    deleteChat: URI + "/chats/delete/:ChatId",
    getChatMessages: URI + "/chats/:ChatId/message",
    addChatMessage: URI + "/chats/message",
  },
  feedback: {
    sendFeedback: URI + "/auth/feedback"
  },
  admin: {
    getUnderReviewUsers: URI + "/auth/admin/get-under-review-users",
    approvedUser: URI + "/auth/admin/approve-user"
  }
});
