import React, { useEffect, useMemo, useState } from "react";
import CustomizedTable, {
  StyledTableCell,
  StyledTableRow,
} from "component/Table";
import { approvedUser, getUnderReviewUsersList } from "services/Admin";
import moment from "moment";
import { CompletedGreen } from "assets";
import InfoDialog from "dialogs/InfoDialog";
import UnderReviewUserEmptyScreen from "pages/AdminPortal/underReviewUserEmptyScreen";
import UnderReviewSkeleton from "./underReviewSkeleton";

export default function AdminPortal({ auth }) {
  const [loading, setLoading] = useState(false);
  const [infoDialog, setInfoDialog] = useState(false);
  const [approvingUserId, setApprovingUserId] = useState("");
  const [underReviewUsers, setUnderReviewUsers] = useState(null);

  const fetchUnderReviewUsers = async () => {
    try {
      setLoading(true);
      const resp = await getUnderReviewUsersList();
      if (resp && resp?.data) {
        setUnderReviewUsers(resp?.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.code === "Unauthorized") {
        window.location.href = "/";
      }
    }
  };

  useEffect(() => {
    fetchUnderReviewUsers();
  }, []);

  const handleApprovedUser = async (userId) => {
    try {
      setApprovingUserId(userId);
      const resp = await approvedUser(userId);
      await fetchUnderReviewUsers();
      setInfoDialog(true);
      setApprovingUserId("");
    } catch (error) {
      setApprovingUserId("");
    }
  };

  const tableColumns = useMemo(() => {
    return [
      { label: "First name", field: "firstName" },
      { label: "Last name", field: "lastName" },
      { label: "Email", field: "email" },
      { label: "Phone Number", field: "phone" },
      { label: "Status", field: "status" },
      { label: "Company name", field: "companyName" },
      { label: "Industry", field: "industry" },
      { label: "Job Title List", field: "jobTitleList" },
      // { label: "AI Suggestions", field: "aiSuggestions" },
      // { label: "Credit", field: "credit" },
      { label: "Created At", field: "createdAt", type: "date" },
      { label: "Action", field: "action", type: "date" },
    ];
  }, []);

  return (
    <div className="py-4 sm:py-6 lg:py-8 xl:py-10 px-4 sm:px-6 lg:px-10 xl:px-12 h-[calc(100vh_-_75px)] overflow-y-auto">
      {infoDialog && (
        <InfoDialog
          open={true}
          setOpen={() => setInfoDialog(false)}
          infoDetail={{
            heading: "User Approved!",
          }}
          handleSubmit={() => {}}
          icon={CompletedGreen}
        />
      )}
      {underReviewUsers?.length === 0 && !loading ? (
        <UnderReviewUserEmptyScreen columns={tableColumns} />
      ) : (
        <CustomizedTable columns={tableColumns} isShowPagination={false}>
          {loading || !underReviewUsers ? (
            <UnderReviewSkeleton columns={tableColumns} numberOfRows={10} />
          ) : (
            underReviewUsers?.map((row, index) => {
              return (
                <StyledTableRow key={index}>
                  {tableColumns.map((column, index) => {
                    return (
                      <StyledTableCell
                        component="td"
                        scope="row"
                        className="w-[20%] p-2"
                      >
                        {column.field === "action" ? (
                          <button
                            className={`bg-[#fff] text-[#353535] text-ellipsis overflow-hidden whitespace-nowrap border rounded-md border-rounded-sm flex items-center gap-2 px-3 py-2`}
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                              handleApprovedUser(row.id);
                            }}
                            disabled={approvingUserId === row.id}
                          >
                            {approvingUserId === row.id
                              ? "Approving..."
                              : "Approve"}
                          </button>
                        ) : column.field === "jobTitleList" ? (
                          <div className="w-full text-ellipsis overflow-hidden whitespace-normal break-words text-[#353535] text-[13px] font-normal leading-5 max-w-[350px] min-w-[200px]">
                            {column.type === "date"
                              ? moment(row[column.field]).format("YYYY-MM-DD")
                              : row[column.field] || "--"}
                          </div>
                        ) : (
                          <div className="w-full text-ellipsis overflow-hidden whitespace-nowrap text-[#353535] text-[13px] font-normal leading-5 max-w-[250px]">
                            {column.type === "date"
                              ? moment(row[column.field]).format("YYYY-MM-DD")
                              : row[column.field] || "--"}
                          </div>
                        )}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              );
              // Add proper empty screen over here
            })
          )}
        </CustomizedTable>
      )}
    </div>
  );
}
