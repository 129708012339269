import { forwardRef, useContext, useEffect, useState } from "react";
import { Dialog, DialogContent, DialogContentText, Slide } from "@mui/material";
import { CompletedGreen, CrossIcon, WarningInfoIcon } from "assets";
import EmailVerificationOtp from "./EmailVerificationOtp";
import DeleteAccount from "./deleteAccount";
import InfoDialog from "./InfoDialog";
import { deleteAccount, sendResetPasswordOtp } from "services/Auth";
import { Paths } from "routes";
import AuthContext from "contexts/authContext";
import { useNavigate } from "react-router-dom";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VerifyDeleteAccount = (props) => {
  const { changeAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const { open, setOpen } = props;
  const [loader, setLoading] = useState("") // sendingOtp | verifyOtp | deletingAccount
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [verificationPopup, setVerificationPopup] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [infoDialog, setInfoDialoag] = useState(false);

  useEffect(() => {
    if(!open && !verificationPopup) {
      setErrors({});
      setEmail("")
    }
  }, [open, verificationPopup])
  
  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  const validate = (email) => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(email)) {
      newErrors.email = "Please enter a valid email address";
    } else {
      delete newErrors.email;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleVerifyEmail = async (e) => {
    e.preventDefault();
    if (validate(email)) {
      try {
        setLoading("sendingOtp");
        await sendResetPasswordOtp({
          Email: email
        })
        setLoading("");
        handleClose();
        setVerificationPopup(true);
      } catch (err) {
        setLoading("");
        setErrors({email: err?.response?.data?.title}) 
      }
    }
  };

  const handleEmailChange = (value) => {
    setEmail(value);
    validate(value)
  };

  const verifiedOtp = () => {
    setVerificationPopup(false);
    setConfirmDelete(true);
  };

  const handleDeleteAccount = async () => {
    try {
      setLoading("deletingAccount");
      await deleteAccount();
      setLoading("");
      changeAuth({ isLoggedIn: false, userData: {} });
      localStorage.removeItem('token');
      localStorage.removeItem('AuthContext');
      navigate(Paths.SignUp);
      setConfirmDelete(false);
      setOpen(false);
      setInfoDialoag(true);
    } catch (err) {
    }
  };

  const handleResend = async () => {
    await sendResetPasswordOtp({
      Email: email
    })
  }

  return (
    <>
      {verificationPopup && (
        <EmailVerificationOtp
          open={verificationPopup}
          setOpen={setVerificationPopup}
          handleSubmit={verifiedOtp}
          email={email}
          handleResend={handleResend}
        />
      )}
      {confirmDelete && (
        <DeleteAccount
          open={confirmDelete}
          setOpen={setConfirmDelete}
          handleSubmit={handleDeleteAccount}
          icon={WarningInfoIcon}
          infoDetail={{
            heading: "Are you sure you want to delete your account?",
            innerContent: "This action is irreversible and you'll permanently lose all data associated with your account."
          }}
          loading={loader === "deletingAccount"}
        />
      )}
      {infoDialog && (
          <InfoDialog
            open={infoDialog}
            setOpen={setInfoDialoag}
            infoDetail={{
              heading: "Account Deleted!",
              innerContent: "",
            }}
            handleSubmit={() => {}}
            icon={CompletedGreen}
          />
        )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        PaperProps={{
          style: {
            maxWidth: "564px",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent className="!py-[52px] !px-[92px] relative">
          <DialogContentText id="alert-dialog-slide-description">
            <img
              src={CrossIcon}
              alt="crossIcon"
              className="ml-auto cursor-pointer absolute top-4 right-4"
              onClick={handleClose}
            />
            <div className="flex flex-col justify-start items-start gap-11 max-w-[380px] w-full mx-auto font-hankengrotesk">
              <div className="flex flex-col gap-7">
                <div className="flex flex-col gap-2">
                  <h6 className="font-semibold text-[28px] leading-9 text-[#353535] m-0">
                    Delete Account
                  </h6>
                  <p className="text-[15px] text-[#1F1F1F] leading-[22px]  -tracking-[0.15px] font-light m-0">
                    In order to delete account, you must verify your email
                    address. Please enter the email address associated with your
                    account and click on verify button.
                  </p>
                </div>

                <form
                  noValidate
                  onSubmit={handleVerifyEmail}
                  className="flex flex-col"
                >
                  <div className="flex flex-col gap-1">
                    <label className="text-[13px] font-normal leading-5 -tracking-[0.15px] text-[#1F1F1F] ">
                      Email Address
                    </label>
                    <input
                      name="email"
                      placeholder="Ex john.doe@example.com"
                      onChange={(e) => handleEmailChange(e.target.value)}
                      onBlur={() => handleEmailChange(email?.trim())}
                      value={email}
                      className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-[14px] px-4 block w-full h-12 outline-none"
                    />
                    {errors.email && (
                      <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
                        {errors.email}
                      </div>
                    )}
                  </div>
                </form>
              </div>
              <div className="w-full flex justify-between items-center gap-4">
                <button
                  type="button"
                  onClick={handleClose}
                  className="btn border border-custom-primary text-[#121212] font-semibold py-[14px] px-8 h-12 flex items-center rounded-lg disabled:opacity-75"
                >
                  Back
                </button>
                <button
                  disabled={loader === "sendingOtp"}
                  type="button"
                  onClick={handleVerifyEmail}
                  className="btn bg-custom-primary text-white font-semibold py-[14px] px-8 h-12 flex items-center rounded-lg disabled:opacity-75"
                >
                  {loader === "sendingOtp" ? "Verifying..." : "Verify"}
                </button>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VerifyDeleteAccount;
