import { forwardRef, useMemo } from "react";
import { Dialog, DialogContent, DialogContentText, Slide } from "@mui/material";
import { CopyIcon, CrossIcon } from "assets";
import { formatToK } from "utils/common";
import moment from "moment";
import JobDetail from "pages/Overview/jobDetail";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ViewJobDetail = (props) => {
  const { open, setOpen, selectedJobDetail } = props;
  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  const renderText = (label, text, index) => {
    return (
      <div className={`flex justify-between py-4 ${index !== jobDetail.length - 1 ? "border-b-[1px] border-[#E5E5E5]" : ""}`} key={index}>
        <p className="text-[#1F1F1F] opacity-60 text-[16px] leading-4 font-normal">{label}</p>
        <p className="text-[#393939] font-semibold text-[15px] leading-5">{text}</p>
      </div>
    );
  };

  const jobDetail = useMemo(() => {
    return [
      { label: "Job Title", value: selectedJobDetail?.title },
      {
        label: "Job ID",
        value: `#${selectedJobDetail?.id}`,
      },
      { label: "Posted", value:  moment(selectedJobDetail?.createdAt).format('DD/MM/YY') },
      {
        label: "Workplace Type",
        value: selectedJobDetail?.workspaceType,
      },
      { label: "Location", value: selectedJobDetail?.location },
      {
        label: "Salary Range",
        value: `${formatToK(
          selectedJobDetail?.minSalary
        )} - ${formatToK(selectedJobDetail?.maxSalary)}`,
      },
      {
        label: "Number of Calls",
        value:
          Number(selectedJobDetail?.failedCallsCount || 0) +
          Number(selectedJobDetail?.successfulCallsCount || 0),
      },
      {
        label: "Strong Candidates",
        value: selectedJobDetail?.strongCandidatesCount || 0,
      },
    ]
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        PaperProps={{
          style: {
            maxWidth: "520px",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent className="!pt-[52px] !pb-[64px] !px-[80px] relative">
          <DialogContentText id="alert-dialog-slide-description">
            <img
              src={CrossIcon}
              alt="crossIcon"
              className="ml-auto cursor-pointer absolute top-4 right-4"
              onClick={handleClose}
            />
            <div className="flex flex-col justify-start items-start gap-3 max-w-[360px] w-full mx-auto font-hankengrotesk scrollbar">
              <div
                className="w-full text-[#353535] font-semibold text-[28px] leading-9 text-center"
              >
                Job Details
              </div>
              <div className="w-full">
                {jobDetail.map((detail,index) => {
                  return renderText(detail.label, detail.value, index)
                })}
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewJobDetail;
