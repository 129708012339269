import React, { useEffect, useRef, useState } from "react";
import JobTypeDialog from "dialogs/JobType";
import NewJobDialog from "dialogs/NewJob";
import {
  CompletedGreen,
  JobHuntIcon,
  NoSearchResult,
  PlusIcon,
  SearchBlue,
} from "assets";
import JobList from "./jobList";
import { createJob, getJobs } from "services/Job";
import JobDetail from "./jobDetail";
import InfoDialog from "dialogs/InfoDialog";
import JobListSkeleton from "./jobListSkeleton";
import { debounce } from "utils/common";
import TabsComponent from "component/Tabs";

export default function OverView({auth}) {
  const [jobTabValue, setJobTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [jobTypeDialog, setJobTypeDialog] = useState(false);
  const [infoDialoag, setInfoDialoag] = useState(false);
  const [jobType, setJobType] = useState("");
  const [jobList, setJobList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const searchRef = useRef(searchValue);
  const jobTabRef = useRef(jobTabValue);

  const callApi = async (value = searchValue, slient = false, status = jobTabValue) => {
    if (!slient) setLoading(true);
    const result = await getJobs(value,status === 0 ? "active" : "archived");
    setJobList(result?.data?.result);
    if (!slient) setLoading(false);
  };

  useEffect(() => {
    callApi();
  }, [jobTabValue]);

  useEffect(() => {
    callApi();

    const intervalId = setInterval(() => {
      callApi(searchRef.current, true, jobTabRef.current);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const debouncedSearch = debounce(callApi, 500);

  const handleSearchJob = (value) => {
    setSearchValue(value);
    searchRef.current = value;
    debouncedSearch(value);
  };

  const handleCreateJob = async (payload) => {
    setJobType(false);
    setLoading(true);
    const result = await createJob(payload);
    if (result.data) {
      setInfoDialoag(true);
      await callApi();
      setLoading(false);
    }
  };

  const renderEmptyScreen = () => {
    return (
      <div className="h-full w-full bg-white border border-[#E5E5E5] rounded-lg flex flex-col justify-center items-center gap-6 p-4">
        <div className="w-full max-w-[525px] mx-auto flex flex-col justify-center items-center gap-[6px] text-center">
          <img
            src={searchValue ? NoSearchResult : JobHuntIcon}
            alt="JobHunt"
            className="max-w-full h-auto"
          />
          <p className="m-0 text-center text-xl sm:text-2xl leading-7 sm:leading-8 text-[#2E2E2E] font-medium">
            {jobTabValue === 1
              ? "There are no archived jobs yet."
              : searchValue
              ? "No Result Found"
              : `Hey ${
                  auth?.userData?.FirstName + " " + auth?.userData?.LastName
                } Looks like you're new here.`}
          </p>
          <p className="text-[#2E2E2E] text-[16px] leading-5 font-light max-w-[310px]">
            {searchValue
              ? "We couldn't find any results based on your search, please try again."
              : "Click below to start making automated calls to your potential candidates!"}
          </p>
        </div>
        {searchValue ? (
          <button
            className="bg-blue-500 text-white px-4 sm:px-6 py-2 rounded-md text-sm sm:text-base"
            onClick={() => callApi()}
          >
            Refresh
          </button>
        ) : (
          <button
            className="mt-4 bg-blue-500 text-white px-4 sm:px-6 py-2 rounded-md text-sm sm:text-base"
            onClick={() => setJobType("candidates")}
          >
            + New Job
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="py-4 sm:py-6 lg:py-8 px-4 sm:px-6 lg:px-10 h-[calc(100vh_-_75px)]">
      <div className="h-full bg-[#FDFEFF] border border-[#E5E5E5] rounded-xl overflow-hidden">
        {jobTypeDialog && (
          <JobTypeDialog
            open={jobTypeDialog}
            setOpen={setJobTypeDialog}
            handleSelectJobType={(jobType) => {
              setJobTypeDialog(false);
              setJobType(jobType);
            }}
          />
        )}
        {jobType && (
          <NewJobDialog
            open={jobType ? true : false}
            setOpen={(open) => setJobType(open ? jobType : "")}
            handleCreateJob={handleCreateJob}
          />
        )}
        {infoDialoag && (
          <InfoDialog
            open={infoDialoag}
            setOpen={setInfoDialoag}
            infoDetail={{
              heading: "Job Posted Successfully!",
              innerContent: "",
            }}
            handleSubmit={() => {}}
            icon={CompletedGreen}
          />
        )}
       
        <div
          className={`w-full h-full flex flex-col bg-[#FDFEFF] rounded-xl ${
            jobList.length > 0 ? "p-0" : "px-8 py-7"
          }`}
        >
          <div
            className={`flex flex-col sm:flex-row justify-between items-center mb-3 gap-4 ${
              jobList.length > 0 ? "px-8 pt-7" : "p-0"
            }`}
          >
            <h6
              className={`m-0 text-2xl sm:text-[28px] font-semibold leading-8 sm:leading-9 text-[#353535]`}
            >
              Latest Jobs
            </h6>
            <div
              className={`flex items-center gap-1 border-[1.2px] border-[#e5e5e5] rounded-lg px-4 sm:px-6 py-2 sm:py-3 w-full sm:w-auto`}
            >
              <img src={SearchBlue} alt="search-icon" className="w-5 h-5" />
              <input
                type="text"
                placeholder="Search for Specific Job"
                className="text-sm leading-5 font-normal text-[#121212] bg-transparent border-none focus-visible:outline-none w-full"
                onChange={(event) => handleSearchJob(event.target.value)}
                value={searchValue}
              />
            </div>
            <button
              className={`bg-[#2764D2] text-white pl-[28px] h-12 pr-[36px] py-[14px] rounded-lg whitespace-nowrap text-sm sm:text-base font-semibold leading-[19px] flex items-center`}
              onClick={() => setJobType("candidates")}
            >
              <img src={PlusIcon} alt="plus-icon" className="w-4 h-4 mr-2" />
              New Job
            </button>
          </div>
          {loading ? (
            <div className="flex gap-4 flex-col px-4 sm:px-6">
              {[0, 1, 2, 3].map((_, index) => (
                <JobListSkeleton key={index} />
              ))}
            </div>
          ) : (
            <div className={`p-0 h-[calc(100%_-_72px)] flex flex-col gap-4`}>
              <div className={`${jobList && jobList?.length === 0 ? "px-0" : "px-8"}`}>
                <TabsComponent
                  tabValue={jobTabValue}
                  setTabValue={(newValue) => {
                    setJobTabValue(newValue);
                    jobTabRef.current = newValue
                    callApi(undefined, undefined, newValue)
                  }
                }
                  tabItems={[
                    { label: "Active Jobs" },
                    { label: "Archived Jobs" },
                  ]}
                  isFromJobDetail={true}
                  tabBackgroundColor="FDFEFF"
                />
              </div>
              <div
                className={`h-[calc(100%_-_29px)] flex flex-col gap-4 overflow-auto scrollbar  ${jobList && jobList?.length === 0 ? "pb-0 px-0" : "px-8 pb-12"}`}
              >
                {jobList && jobList?.length === 0 ? renderEmptyScreen() : jobList?.map((job) => (
                  <JobList
                    key={job?.id}
                    job={job}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
