import AuthContext from "contexts/authContext";
import { useContext, useEffect } from "react";
import { Paths } from "routes";
import { getUser } from "services/Auth";

let intervalIdForGetUser = null;
export const clearIntervalForGetUser = () => {
  if (intervalIdForGetUser) {
    clearInterval(intervalIdForGetUser);
    intervalIdForGetUser = null;
  }
};
const AppInit = () => {
  const { auth, changeAuth } = useContext(AuthContext);

  const getUserData = async () => {
    try {
      let res = await getUser();
      changeAuth({
        isLoggedIn: true,
        userData: { ...auth.userData, ...res.data },
      });
    } catch {
      clearIntervalForGetUser();
      localStorage.removeItem("token");
      localStorage.removeItem("AuthContext");
      changeAuth({ isLoggedIn: false, userData: {} });
      window.location.href = Paths.Login;
    }
  };

  useEffect(() => {
    if (auth.isLoggedIn) {
      getUserData();
      intervalIdForGetUser = setInterval(getUserData, 1000);
    }
    return () => clearIntervalForGetUser();
  }, [auth.userData.token]);

  return <></>;
};

export default AppInit;
