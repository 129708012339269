import { endPoints } from "services/endPoints";
import axiosInstance from "utils/axiosInstance";

export const getUnderReviewUsersList = async () => {
  return await axiosInstance.get(endPoints.admin.getUnderReviewUsers);
};

export const approvedUser = async (userId) => {
  return await axiosInstance.post(endPoints.admin.approvedUser, {
    userIdToApprove: userId
  });
};