import {
  AIStarIcon,
  BriefcaseBlackIcon,
  CompletedGreen,
  EditPencilBlue,
  EmailBlackIcon,
  EmptyNoteSummary,
  MoneyBagIcon,
  PhoneIcon,
  RemoveUserRedIcon,
  WarningInfoIcon,
} from "assets";
import TabsComponent from "component/Tabs";
import ContactCandidate from "dialogs/ContactCandidate";
import TranscriptionDetail from "dialogs/TranscriptionDetail";
import React, { Fragment, useMemo } from "react";
import { useState } from "react";
import { callStatusDetail, categoryChipColor, formatToK, renderCategoryValue } from "utils/common";
import ViewJobDetail from "dialogs/ViewJobDetails";
import DeleteAccount from "dialogs/deleteAccount";
import { updateCandidateNotes } from "services/Job";
import InfoDialog from "dialogs/InfoDialog";
import CustomTooltip from "component/Tooltip";

export default function SummaryDetail({
  selectedCandidate,
  selectedJobDetail,
  isFromSingleCandidate,
  deleteCandidate,
  handleExecuteCall,
}) {
  const [tabValue, setTabValue] = useState(0);
  const [isContactCandidate, setIsContactCandidate] = useState(false);
  const [isJobDetail, setIsJobDetail] = useState(false);
  const [removeCandidatePoup, setRemoveCandidatePoup] = useState(false);
  const [noteDetail, setNoteDetail] = useState(
    selectedCandidate?.notes ? selectedCandidate?.notes : ""
  );
  const [noteDetailValidationError, setNoteDetailValidationError] =
    useState("");
  const [isNoteEditView, setIsNoteEditView] = useState(false);
  const [infoDialog, setInfoDialog] = useState(false);
  const summary = selectedCandidate?.summary
    ? JSON.parse(selectedCandidate?.summary || {})
    : null;

  const handleUpdateNotes = async () => {
    if (!noteDetail) {
      setNoteDetailValidationError("Summary is required");
      return;
    }
    setIsNoteEditView(false);
    await updateCandidateNotes(selectedJobDetail?.id, selectedCandidate?.id, {
      notes: noteDetail,
    });
    setInfoDialog(true);
  };

  const statusDetail = useMemo(() => {    
    return callStatusDetail(selectedCandidate?.callStatus)
  }, [selectedCandidate])

  const renderSummary = () => {
    return (
      <div
        className={`flex flex-col gap-1 ${
          isFromSingleCandidate
            ? "h-[calc(100%_-_162px)]"
            : "h-[calc(100%_-_74px)]"
        }`}
      >
        <div className="flex justify-between items-center">
          <div className="flex gap-1">
            <img src={AIStarIcon} alt="AIIcon" width={20} height={20} />
            <span className="text-[13px] leading-5 font-normal -tracking-[1%] text-[#1f1f1f]">
              AI Summary
            </span>
          </div>
          {isFromSingleCandidate && (
            <div
              className="flex gap-1 cursor-pointer hover:underline"
              onClick={() => setIsNoteEditView(true)}
            >
              <img
                src={EditPencilBlue}
                alt="EditPencil"
                width={16}
                height={16}
              />
              <span className="text-[13px] leading-5 font-semibold text-[#1f1f1f]">
                Edit Summary
              </span>
            </div>
          )}
        </div>
        {isNoteEditView ? (
          <div className="flex flex-col gap-[6px]">
            <textarea
              id="summary"
              name="summary"
              rows="10"
              onChange={(event) => {
                setNoteDetail(event.target.value);
                setNoteDetailValidationError(
                  event.target.value ? "" : "Summary is required"
                );
              }}
              className="input text-gray-700 bg-white border border-gray-300 rounded-lg max-h-[258px] h-full py-[14px] px-4 w-full outline-none resize-none"
              value={noteDetail}
            />
            {noteDetailValidationError && (
              <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
                {noteDetailValidationError}
              </span>
            )}
          </div>
        ) : (
          <div
            className={`${
              isFromSingleCandidate ? "min-h-[258px]" : ""
            } border border-[#E5E5E5] px-4 py-[14px] rounded-lg h-full overflow-auto scrollbar`}
          >
            {noteDetail ? (
              noteDetail
            ) : (
              <div className="h-full flex justify-center items-center">
                <div className="w-full max-w-[525px] mx-auto flex flex-col justify-center items-center gap-4 text-center">
                  <img
                    src={EmptyNoteSummary}
                    alt="EmptyNoteSummary"
                    className="max-w-full h-auto"
                  />
                  <p className="m-0 text-center text-lg leading-[22px] text-[#06102580] -tracking-[0.18px] font-medium">
                    {["completed", "busy", "no-answer"].includes(
                      selectedCandidate?.callStatus
                    )
                      ? "You can make your own notes"
                      : isFromSingleCandidate
                      ? "The AI will fill this section for you after the call takes place, but you can make your own notes in the meantime"
                      : "The AI will fill this section for you after the call has taken place. You can also come back and edit AI text later."}
                  </p>
                  {isFromSingleCandidate && selectedCandidate?.callStatus !== "completed" && (
                      <CustomTooltip
                        title={statusDetail.tooltip}
                        arrow
                        placement="top-end"
                      >
                        <button
                          className={`${statusDetail.color} w-[126px] text-[#353535] font-medium text-xs leading-4 text-ellipsis overflow-hidden whitespace-nowrap border rounded-md border-rounded-sm flex items-center gap-2 px-3 py-2`}
                          onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            handleExecuteCall();
                          }}
                          disabled={!["pending","busy", "no-answer", "failed"].includes(selectedCandidate?.callStatus)}
                        >
                          <img
                            src={statusDetail.icon}
                            width={16}
                            height={16}
                            alt="phone-icon"
                          />
                          {statusDetail.text}
                        </button>
                      </CustomTooltip>
                    )}
                </div>
              </div>
            )}
          </div>
        )}
        {isFromSingleCandidate && tabValue === 0 && (
          <div
            className={`flex gap-2 justify-between ${
              noteDetailValidationError ? "mt-[40px]" : "mt-[69px]"
            } min-h-[30px] items-center`}
          >
            {isNoteEditView && (
              <div className="flex gap-2">
                <button
                  type="button"
                  className={`whitespace-nowrap btn bg-white h-[30px] flex items-center justify-center border-custom-primary border-[1px] border-purple font-medium text-[13px] leading-[16px] text-[#121212] py-[10px] px-[16px] rounded-lg`}
                  onClick={() => {
                    setIsNoteEditView(false);
                    setNoteDetail("");
                    setNoteDetailValidationError("");
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={`whitespace-nowrap btn bg-custom-primary h-[30px] flex items-center justify-center border-custom-primary border-[1px] border-purple font-medium text-[13px] leading-[16px] text-[#fff] py-[10px] px-[16px] rounded-lg`}
                  onClick={() => handleUpdateNotes()}
                >
                  Update Summary
                </button>
              </div>
            )}
            <div
              onClick={() => setRemoveCandidatePoup(true)}
              className="w-auto ml-auto justify-end items-center flex gap-[6px] cursor-pointer hover:underline"
            >
              <img
                src={RemoveUserRedIcon}
                width={16}
                height={16}
                alt="RemoveUserRedIcon"
              />
              <span className="text-[#121212] font-medium leading-4 text-[13px]">
                Remove Candidate
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderTranscription = () => {
    return (
      <TranscriptionDetail
        selectedCandidate={selectedCandidate}
        isShowDetailOnly={true}
      />
    );
  };

  const getCandidateSalary = () => {
    return summary?.desired_salary && typeof summary.desired_salary === "number"
      ? formatToK(summary.desired_salary)
      : summary?.desired_salary || "N/A";
  };

  return (
    <div className="flex gap-8 flex-col h-[calc(100%_-_31px)]">
      {isContactCandidate && (
        <ContactCandidate
          open={isContactCandidate}
          setOpen={setIsContactCandidate}
          selectedCandidate={selectedCandidate}
        />
      )}
      {isJobDetail && (
        <ViewJobDetail
          open={isJobDetail}
          setOpen={setIsJobDetail}
          selectedJobDetail={selectedJobDetail}
        />
      )}
      {removeCandidatePoup && (
        <DeleteAccount
          open={removeCandidatePoup}
          setOpen={setRemoveCandidatePoup}
          // call delete api here and navigate to "/jobId" once candidate route changes done
          handleSubmit={() => deleteCandidate && deleteCandidate()}
          icon={WarningInfoIcon}
          infoDetail={{
            heading: `Remove ${selectedCandidate?.firstName} from candidates list?`,
          }}
        />
      )}
      {infoDialog && (
        <InfoDialog
          open={infoDialog}
          setOpen={() => setInfoDialog(false)}
          infoDetail={{
            heading: "Changes Saved!",
          }}
          handleSubmit={() => {}}
          icon={CompletedGreen}
        />
      )}
      <div className="flex flex-col gap-7">
        <div className="w-full flex flex-col gap-3">
          <div className="w-full flex justify-between items-center">
            <h3 className="text-[28px] sm:text-xl leading-[36px] -tracking-[-0.2px] font-semibold text-[#353535] w-full">
              {selectedCandidate?.firstName + " " + selectedCandidate?.lastName}{" "}
              <span className="font-light">/</span>{" "}
              <span className="text-[28px] sm:text-xl leading-[36px] -tracking-[-0.2px] font-normal text-[#353535]">
                {selectedJobDetail?.title}
              </span>
            </h3>
            <div className="w-full flex flex-row justify-end gap-3">
              <button
                type="button"
                className={` btn bg-white h-[36px] flex items-center justify-center border-custom-primary border-[1px] border-purple font-medium text-[13px] leading-[16px] text-[#121212] py-[10px] px-[16px] rounded-lg`}
                onClick={() => setIsJobDetail(true)}
              >
                Job Details
              </button>
              <button
                type="button"
                className=" btn bg-custom-primary h-[36px] flex items-center justify-center text-white font-medium py-[10px] px-[16px] text-[13px] leading-[16px] rounded-lg disabled:opacity-75"
                onClick={() => setIsContactCandidate(true)}
              >
                Contact Candidate
              </button>
            </div>
          </div>
          {isFromSingleCandidate && (
            <div className="w-full flex items-center gap-5">
              {[
                { icon: EmailBlackIcon, data: selectedCandidate?.Email },
                { icon: PhoneIcon, data: selectedCandidate?.phoneNumber },
                {
                  icon: BriefcaseBlackIcon,
                  data: summary?.experience_year
                    ? `${summary?.experience_year} years experience`
                    : "N/A",
                },
                {
                  icon: MoneyBagIcon,
                  data: getCandidateSalary(),
                },
              ].map((moreDetail) => {
                return (
                  <div className="flex items-center gap-[6px] border-r border-[#E5E5E5] pr-5 last:border-0">
                    <img
                      src={moreDetail.icon}
                      width={16}
                      height={16}
                      color="#353535"
                    />
                    <div className="text-[13px] font-normal leading-5 text-[#353535]">
                      {moreDetail.data || "N/A"}
                    </div>
                  </div>
                );
              })}
              <div className="flex items-center gap-[6px] border-r border-[#E5E5E5] pr-5 last:border-0">
                <CustomTooltip
                  title={summary?.category_reason || ""}
                  arrow
                  placement="top-end"
                >
                  <div
                    className={`${categoryChipColor(
                      selectedCandidate?.category,
                      selectedCandidate?.callStatus
                    )} text-[13px] px-4 rounded-[20px] w-fit m-auto capitalize`}
                  >
                    {renderCategoryValue(selectedCandidate?.category, selectedCandidate?.callStatus)}
                  </div>
                </CustomTooltip>
              </div>
            </div>
          )}
        </div>
        {isFromSingleCandidate && (
          <Fragment>
            <TabsComponent
              tabValue={tabValue}
              setTabValue={(newValue) => setTabValue(newValue)}
              tabItems={[{ label: "Summary" }, { label: "Transcription" }]}
              isFromJobDetail={true}
            />
          </Fragment>
        )}
      </div>
      {isFromSingleCandidate
        ? tabValue === 0
          ? renderSummary()
          : renderTranscription()
        : renderSummary()}
    </div>
  );
}
