import { ArrowDown, ArrowUpSm, circleDownArrowIcon, circleUpArrowIcon } from "assets";
import React, { useState } from "react";

function Accordion({ a, q, border = true }) {
  const [open, setOpen] = useState(false);
  return (
    <div className={border ? `border-b-[1px] border-[#EFEFEF] lg:py-7 sm:py-5 py-4` : "lg:py-7 sm:py-5 py-4"}>
      <div
        className="cursor-pointer flex justify-between items-center lg:text-[28px] md:text-2xl sm:text-xl text-lg font-semibold text-[#121212] lg:leading-9 md:leading-7 leading-5"
        onClick={() => setOpen(!open)}
      >
        {q}
        {open ? (
          <div className="md:w-6 w-5 md:h-6 h-5 rounded-full bg-[#F9F9F9] flex justify-center items-center">
            <img src={ArrowDown} alt="ArrowUp" className="rotate-180"/>
          </div>
        ) : (
          <div className="md:w-6 w-5 md:h-6 h-5 rounded-full bg-[#F9F9F9] flex justify-center items-center">
            <img src={ArrowDown} alt="ArrowDown"/>
          </div>
        )}
      </div>
      <div
        className={`transition-all duration-700 ease-in-out overflow-hidden ${open ? "max-h-[500px] opacity-100" : "max-h-0 opacity-0"}`}
      >
        <div className="lg:text-lg md:text-base text-sm font-light lg:leading-[26px] md:leading-6 leading-5 lg:mt-[14px] md:mt-3 mt-2 w-full lg:max-w-[755px] md:max-w-[600px] max-w-full text-[#353535]">
          {a}
        </div>
      </div>
    </div>
  );
}

export default Accordion;
