import { endPoints } from "services/endPoints";
import axiosInstance from "utils/axiosInstance";

export const loginService = async (data) => {
  return await axiosInstance.post(endPoints.auth.login, data, {
    hasLoader: true,
  });
};

export const signUpService = async (data) => {
  return await axiosInstance.post(endPoints.auth.signup, data, {
    hasLoader: false,
  });
};

export const getUser = async () => {
  return await axiosInstance.get(endPoints.auth.getUser);
};

export const updateCompanyName = async (data) => {
  return await axiosInstance.post(endPoints.auth.updateCompanyName, data);
};

export const changePassword = async (data) => {
  return await axiosInstance.post(endPoints.auth.changePassword, data);
};

export const updatePassword = async (data) => {
  return await axiosInstance.post(endPoints.auth.updatePassword, data);
};

export const sendSignUpOtp = async (data) => {
  return await axiosInstance.post(endPoints.auth.sendOtp, data);
};

export const sendResetPasswordOtp = async (data) => {
  return await axiosInstance.post(endPoints.auth.sendResetPasswordOtp, data);
}

export const validateOtp = async (data) => {
  return await axiosInstance.post(endPoints.auth.validateOtp, data);
};

export const deleteAccount = async () => {
  return await axiosInstance.post(endPoints.auth.deleteAccount, {});
};