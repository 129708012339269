import Accordion from "component/Accordion";
import HowItWork from "component/HowItWork";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Paths } from "routes";
import {
  BannerSectionImage,
  BannerBgImage,
  FeatureBgImage,
  ChatIcon,
  ChatScreenImg,
  UserIcon,
  UserScreenImg,
  UserGradientIcon,
  DocumentIcon,
  DocumentScreenImg,
  PricingBgImage,
  CheckIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  CrossIcon,
  HamburgerIcon,
} from "assets";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import SubscriptionCard from "component/SubscriptionCard";
import { mainNavbarItems, subscriptionPlans } from "utils/common";
import Login from "dialogs/Login";
import ForgotPasswordVerifyEmail from "dialogs/ForgotPassVerifyEmail";

export default function LandingPage() {
  const { section } = useParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [loginPopup, setLoginPopup] = useState(false);
  const [forgotPasswordPopup, setForgotPasswordPopup] = useState(false);

  useEffect(() => {
    if (mainNavbarItems?.find((item) => item.id === section)) {
      setTimeout(() => {
        document.getElementById(section).scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }, [section]);

  const renderNav = (isFromFooter = false) => {
    return (
      <ul
        className={`w-full items-center ${
          isFromFooter ? "flex gap-5" : "hidden md:flex gap-7"
        }`}
      >
        {mainNavbarItems.map((item) => (
          <li
            className={`cursor-pointer whitespace-pre ${
              isFromFooter
                ? "text-white sm:text-[15px] text-[13px]"
                : "text-[#353535] text-[15px]"
            } leading-5 font-medium hover:text-custom-primary`}
            onClick={() => {
              item.id === "contact-us"
                ? navigate(Paths.ContactUs)
                : document
                    .getElementById(item.id)
                    .scrollIntoView({ behavior: "smooth" });
            }}
          >
            {item.label}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <>
      {isSidebarOpen && (
        <Drawer
          anchor="left"
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          classes={{
            paper: "p-4",
          }}
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onKeyDown={() => setIsSidebarOpen(false)}
            className="h-full"
          >
            <div className="flex justify-between items-center">
              <h5 className="m-0 md:hidden block text-[#121212] font-semibold text-2xl leading-8 tracking-[0.48px] cursor-pointer md:pr-6 pr-0 md:border-r border-r-0 border-[#E5E5E5]">
                Dial<span className="text-[#2764D2]">r</span>
              </h5>
              <div
                className="w-6 h-6 flex justify-center items-center cursor-pointer"
                onClick={() => setIsSidebarOpen(false)}
              >
                <img src={CrossIcon} alt="close-icon" />
              </div>
            </div>
            <List className="!p-0 h-full flex flex-col gap-3 !mt-5">
              {mainNavbarItems.map((item, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  onClick={() => {
                    setIsSidebarOpen(false);
                    document
                      .getElementById(item.id)
                      ?.scrollIntoView({ behavior: "smooth" });
                  }}
                  className="!cursor-pointer"
                >
                  <ListItemButton className="!p-0">
                    <ListItemText
                      primary={item.label}
                      classes={{
                        primary:
                          "text-[#353535] !text-lg !leading-8 !font-medium whitespace-nowrap",
                      }}
                      className="!m-0"
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      )}
      {loginPopup && (
        <Login
          open={loginPopup}
          setOpen={setLoginPopup}
          handleForgotPassword={() => {
            setForgotPasswordPopup(true);
            setLoginPopup(false);
          }}
        />
      )}

      <ForgotPasswordVerifyEmail
        open={forgotPasswordPopup}
        setOpen={setForgotPasswordPopup}
      />

      <nav className="bg-white py-[14px] w-full fixed top-0 left-0 z-[9999]">
        <div className="xl:max-w-[1114px] lg:max-w-[1024px] md:max-w-[640px] max-w-full md:mx-auto mx-4 flex justify-between items-center gap-5">
          <div className="flex items-center gap-8">
            <button
              className="md:hidden p-2 bg-transparent border border-[#ffffff1a] rounded"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            >
              <img src={isSidebarOpen ? CrossIcon : HamburgerIcon} alt="menu" />
            </button>
            <h5 className="m-0 md:block hidden text-[#121212] font-semibold text-2xl leading-8 tracking-[0.48px] cursor-pointer md:pr-6 pr-0 md:border-r border-r-0 border-[#E5E5E5]">
              Dial<span className="text-[#2764D2]">r</span>
            </h5>
            {renderNav()}
          </div>
          <div className="flex items-center gap-7">
            <button
              className="btn bg-white text-[#353535] font-medium leading-5 whitespace-nowrap text-[15px] cursor-pointer hover:text-custom-primary"
              onClick={() => setLoginPopup(true)}
            >
              Log in
            </button>
            <button
              className="btn w-full min-w-[107px] bg-custom-primary text-white font-semibold leading-5 py-3 px-[22px] whitespace-nowrap rounded-lg disabled:opacity-75"
              onClick={() => navigate("/sign-up")}
            >
              Sign up
            </button>
          </div>
        </div>
      </nav>
      <section
        className={`h-full xl:max-h-[728px] lg:max-h-[628px] md:max-h-[428px] max-h-fit min-h-[calc(100%_+_90px)] sm:min-h-0 flex sm:items-center items-start relative md:px-0 px-5 bg-[#061025] bg-right bg-cover`}
        style={{ backgroundImage: `url(${BannerBgImage})` }}
      >
        <div className="h-full xl:max-w-[1116px] lg:max-w-[1024px] md:max-w-[640px] max-w-full mx-auto">
          <div className="h-full flex sm:flex-row flex-col justify-center items-center xl:gap-[82px] lg:gap-[52px] sm:gap-9 gap-6">
            <div className="w-full xl:max-w-[500px] lg:max-w-[380px] md:max-w-[320px] max-w-full flex flex-col md:gap-10 gap-5">
              <div className="flex flex-col items-start">
                <div className="w-fit lg:px-[22px] px-[11px] lg:py-[11px] py-2 rounded-[41px] bg-[#2764d21f] md:mb-5 mb-3">
                  <span
                    className="lg:text-base text-sm font-medium lg:leading-[22px] sm:leading-4 leading-3 -tracking-[0.16px]"
                    style={{
                      background:
                        "linear-gradient(89deg, #FFF 21.38%, #B5CFFF 89.92%)",
                      backgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    Handle Recruitment Calls with AI
                  </span>
                </div>
                <h1 className="w-full text-white xl:text-[52px] lg:text-[40px] sm:text-[34px] text-2xl font-bold xl:leading-[60px] lg:leading-[50px] sm:leading-tight leading-tight mb-[10px]">
                  {/* Elevate Your Hiring Game with
                  <span
                    style={{
                      background:
                        "linear-gradient(89deg, #FFF 21.38%, #B5CFFF 89.92%)",
                      backgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  > AI-Driven </span> 
                  Talent Screening */}
                  Get AI To Call Hundreds of Potential Candidates In Minutes
                </h1>
                <p className="text-white sm:text-lg text-base font-light xl:leading-[26px] leading-normal">
                  Automate recruitment with AI that calls, screens, and stores
                  candidates in a database, finding top talent instantly.
                </p>
              </div>
              <div className="flex items-center sm:gap-[14px] gap-2 sm:flex-nowrap flex-wrap">
                <div className="flex-1 lg:py-[14px] py-[10px] lg:pr-[10px] pr-2 lg:pl-4 pl-[14px] rounded-lg border border-[#FFFFFF80] bg-[#FFFFFF0D]">
                  <input
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    onBlur={() => setEmail(email?.trim())}
                    type="email"
                    placeholder="Email adddress"
                    className="w-full text-white border-none bg-transparent text-[15px] font-light leading-5 focus-visible:outline-none"
                  />
                </div>
                <button
                  type="button"
                  className="w-full sm:max-w-[140px] max-w-full bg-custom-primary lg:py-[18px] py-[14px] lg:px-8 px-6 rounded-[10px] capitalize text-white lg:text-[17px] text-sm font-semibold leading-[19px] whitespace-nowrap"
                  onClick={() => navigate(`${Paths.SignUp}?email=${email}`)}
                >
                  try now
                </button>
              </div>
            </div>
            <div className="flex justify-center items-center">
              <img src={BannerSectionImage} alt="banner-section-image" />
            </div>
          </div>
        </div>
      </section>
      <section className="md:py-20 py-10 md:px-0 px-5" id="how-it-works">
        <div className="flex flex-col">
          <h4 class="lg:text-4xl md:text-3xl text-2xl font-semibold text-[#1F1F1F] lg:leading-[48px] md:leading-8 leading-5 text-center lg:mb-9 md:mb-7 mb-3">
            How it Works
          </h4>
          <HowItWork />
        </div>
      </section>
      <section
        className="xl:pt-[120px] lg:pt-[90px] md:pt-[70px] pt-[50px] bg-cover bg-top md:px-0 px-5"
        id="features"
        style={{ backgroundImage: `url(${FeatureBgImage})` }}
      >
        <div className="xl:max-w-[1120px] lg:max-w-[960px] md:max-w-[740px] sm:max-w-[576px] max-w-full mx-auto md:rounded-[20px_20px_0px_0px] rounded-[16px_16px_0px_0px] bg-[rgba(217,219,253,0.07)] backdrop-blur-[130px]">
          <h5 className="m-0 xl:pt-[58px] lg:pt-12 md:pt-9 sm:pt-7 pt-5 text-center text-white lg:text-4xl md:text-[28px] text-2xl font-semibold lg:leading-[48px] leading-[30px] capitalize">
            features
          </h5>
          <div className="w-full xl:max-w-[825px] lg:max-w-[724px] md:max-w-[640px] max-w-full mx-auto flex flex-col lg:gap-12 md:gap-10 sm:gap-8 gap-6 xl:mt-12 lg:mt-9 md:mt-7 sm:mt-6 mt-5 md:px-0 sm:px-8 px-4">
            <div className="flex flex-col lg:gap-11 gap-7 lg:pb-20 md:pb-10 sm:pb-8 pb-6 border-b border-[#ffffff26]">
              <div className="flex sm:items-center items-start lg:gap-6 md:gap-5 gap-4">
                <div className="xl:min-w-[116px] lg:min-w-[106px] md:min-w-[86px] min-w-[66px] xl:h-[116px] lg:h-[106px] md:h-[86px] h-[66px] flex justify-center items-center bg-[#061025] md:rounded-2xl rounded-xl">
                  <img
                    src={ChatIcon}
                    alt="chat-icon"
                    className="xl:max-w-full lg:max-w-[65%] max-w-[55%]"
                  />
                </div>
                <div className="flex flex-col justify-start gap-1">
                  <h6 className="text-white lg:text-2xl md:text-xl sm:text-lg text-base font-medium lg:leading-8 sm:leading-7 leading-5">
                    Chat with AI Regarding Feedback
                  </h6>
                  <p className="text-white lg:text-lg sm:text-base text-sm font-light lg:leading-[26px] sm:leading-[22px] leading-[18px]">
                    Rather than going through thousands of notes about each
                    call, the AI can provide you with anything you ask it for.
                  </p>
                </div>
              </div>
              <div className="flex justify-center items-center">
                <img src={ChatScreenImg} alt="chat-screen" />
              </div>
            </div>
            <div className="flex flex-col lg:gap-11 gap-7 lg:pb-20 md:pb-10 sm:pb-8 pb-6 border-b border-[#ffffff26]">
              <div className="flex sm:items-center items-start lg:gap-6 md:gap-5 gap-4">
                <div className="xl:min-w-[116px] lg:min-w-[106px] md:min-w-[86px] min-w-[66px] xl:h-[116px] lg:h-[106px] md:h-[86px] h-[66px] flex justify-center items-center bg-[#061025] md:rounded-2xl rounded-xl">
                  <img
                    src={UserGradientIcon}
                    alt="user-icon"
                    className="xl:max-w-full lg:max-w-[65%] max-w-[55%]"
                  />
                </div>
                <div className="flex flex-col justify-start gap-1">
                  <h6 className="text-white lg:text-2xl md:text-xl sm:text-lg text-base font-medium lg:leading-8 sm:leading-7 leading-5">
                    All your valuable data in one place
                  </h6>
                  <p className="text-white lg:text-lg sm:text-base text-sm font-light lg:leading-[26px] sm:leading-[22px] leading-[18px]">
                    Rather than having information scattered across multiple
                    mediums, you can now have all the information you need on a
                    centralized platform.
                  </p>
                </div>
              </div>
              <div className="flex justify-center items-center">
                <img src={UserScreenImg} alt="user-screen" />
              </div>
            </div>
            <div className="flex flex-col lg:gap-11 gap-7 lg:pb-[113px] md:pb-[90px] pb-12">
              <div className="flex sm:items-center items-start lg:gap-6 md:gap-5 gap-4">
                <div className="xl:min-w-[116px] lg:min-w-[106px] md:min-w-[86px] min-w-[66px] xl:h-[116px] lg:h-[106px] md:h-[86px] h-[66px] flex justify-center items-center bg-[#061025] md:rounded-2xl rounded-xl">
                  <img
                    src={DocumentIcon}
                    alt="document-icon"
                    className="xl:max-w-full lg:max-w-[65%] max-w-[55%]"
                  />
                </div>
                <div className="flex flex-col justify-start gap-1">
                  <h6 className="text-white lg:text-2xl md:text-xl sm:text-lg text-base font-medium lg:leading-8 sm:leading-7 leading-5">
                    View AI Summary and Pick the Best Candidate
                  </h6>
                  <p className="text-white lg:text-lg sm:text-base text-sm font-light lg:leading-[26px] sm:leading-[22px] leading-[18px]">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. Lorem Ipsum is a dummy text.
                  </p>
                </div>
              </div>
              <div className="flex justify-center items-center">
                <img src={DocumentScreenImg} alt="document-screen" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="md:px-0 px-5 xl:py-[152px] lg:py-28 md:py-20 py-10"
        id="faq"
      >
        <div className="xl:max-w-[1120px] lg:max-w-[960px] md:max-w-[740px] sm:max-w-[576px] max-w-full mx-auto">
          <h5 className="m-0 text-[#1F1F1F] lg:text-4xl md:text-[28px] text-2xl font-semibold lg:leading-[48px] leading-[30px] capitalize">
            FAQs
          </h5>
          <div className="lg:mt-8 sm:mt-6 mt-4 flex flex-col">
            <Accordion
              q="How will DialR help us make more placements?"
              a="DialR enables recruitment companies to contact hundreds of candidates within 30 minutes, identifying those who are actively seeking new opportunities and interested in being put forward for roles. Instead of spending 5-6 hours making manual calls, you can upload candidate details and let the software do the work for you, even during evenings and weekends."
            />
            <Accordion
              q="Does DialR sound like a human?"
              a="Candidates will currently know they're interacting with AI, but that’s perfectly fine. We've carefully crafted our scripts and trained the AI to handle these conversations smoothly."
            />
            <Accordion
              q="Will overcalling or spamming be an issue if everyone uses DialR?"
              a="We’ve taken this into account and will ensure that DialR is only available to trusted, accredited agencies and established businesses. This way, we prevent misuse of the system and avoid overwhelming candidates with excessive calls."
            />
            <Accordion
              q="How much money can we save with DialR?"
              a="Consider how much you currently spend on a resourcer to call 100 candidates a day. DialR automates this process, handling all the calls simultaneously in a fraction of the time. It not only saves on employee costs but also boosts your productivity by up to 500%."
              border={false}
            />
          </div>
        </div>
      </section>
      <section className="bg-[#061025]">
        <section
          className="xl:pt-[100px] lg:pt-[90px] md:pt-[70px] pt-[50px] h-full lg:min-h-[804px] min-h-fit lg:pb-0 md:pb-[70px] pb-[50px] bg-cover bg-top md:px-0 px-5"
          id="pricing"
          style={{ backgroundImage: `url(${PricingBgImage})` }}
        >
          <div className="xl:max-w-[1120px] lg:max-w-[960px] md:max-w-[740px] sm:max-w-[576px] max-w-full mx-auto">
            <h5 className="m-0 text-white lg:text-4xl md:text-[28px] text-2xl font-semibold lg:leading-[48px] leading-[30px] capitalize">
              pricing plans
            </h5>
            <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-5 lg:mt-[52px] sm:mt-8 mt-6 sm:pb-0 pb-[50px]">
              {subscriptionPlans?.map((plan) => {
                return (
                  <SubscriptionCard
                    plan={{ ...plan, buttonText: "get started" }}
                    onClick={() =>
                      navigate(
                        plan?.isCustomPrice ? Paths.ContactUs : Paths.SignUp
                      )
                    }
                  />
                );
              })}
            </div>
          </div>
        </section>
        <footer className="md:h-full h-auto md:px-0 px-5 md:min-h-[272px] min-h-fit bg-[#061025]">
          <div className="h-full flex flex-col justify-between md:pt-[42px] pt-7 md:min-h-[272px] min-h-fit xl:max-w-[1120px] lg:max-w-[960px] md:max-w-[740px] sm:max-w-[576px] max-w-full mx-auto">
            <div className="md:w-full w-auto flex justify-between md:items-center items-start md:pb-0 pb-7 md:flex-nowrap flex-wrap md:gap-0 gap-[18px]">
              <div className="md:w-full w-auto flex md:flex-row flex-col md:items-center items-start">
                <h6 className="md:pb-0 pb-3 text-white text-2xl font-semibold sm:leading-9 leading-normal tracking-[0.48px] cursor-pointer md:pr-[31px] pr-0 md:border-r border-r-0 border-[#e5e5e533]">
                  Dialr
                </h6>
                <div className="md:pl-8 pl-0 flex flex-1 w-full gap-5 sm:items-center flex-col sm:flex-row">
                  <div className="flex-1">{renderNav(true)}</div>
                </div>
              </div>
              <div className="flex items-center md:gap-6 gap-4">
                <img
                  src={InstagramIcon}
                  alt="instagram-icon"
                  width={20}
                  height={20}
                  className="cursor-pointer"
                />
                <img
                  src={TwitterIcon}
                  alt="twitter-icon"
                  width={20}
                  height={20}
                  className="cursor-pointer"
                />
                <img
                  src={LinkedinIcon}
                  alt="linkedin-icon"
                  width={20}
                  height={20}
                  className="cursor-pointer"
                />
              </div>
            </div>
            <div className="py-7 border-[#e5e5e533] border-t flex justify-between items-center gap-5 flex-col sm:flex-row">
              <span className="text-white text-[13px] font-light leading-5 cursor-pointer opacity-70">
                © 2024 Dialr, All Rights Reserved
              </span>
              <div className="flex gap-[33px] items-center">
                <span
                  className="text-white text-[13px] font-light leading-5 cursor-pointer opacity-70"
                  onClick={() =>
                    window.open(
                      "https://app.termly.io/policy-viewer/policy.html?policyUUID=0b67ab5b-10ce-4dfc-b0c9-e289d9c54cdb",
                      "_blank"
                    )
                  }
                >
                  Privacy Policy
                </span>
                <span
                  className="text-white text-[13px] font-light leading-5 cursor-pointer opacity-70"
                  onClick={() =>
                    window.open(
                      "https://app.termly.io/policy-viewer/policy.html?policyUUID=c9e5e90a-fadc-424c-942f-13f8d14b92e7",
                      "_blank"
                    )
                  }
                >
                  Terms & Conditions
                </span>
              </div>
            </div>
          </div>
        </footer>
      </section>
    </>
  );
}
