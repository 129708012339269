import { Avatar } from "@mui/material";
import _ from "lodash";
import {
  ArrowBackIcon,
  CompletedGreen,
  ContactUsIcon,
  CreditCardIcon,
  EditIcon,
  KeyIcon,
  LogoutIcon,
  TermsOfServiceIcon,
} from "assets";
import AuthContext from "contexts/authContext";
import ChangePassword from "dialogs/ChangePassword";
import InfoDialog from "dialogs/InfoDialog";
import VerifyDeleteAccount from "dialogs/VerifyDeleteAccount";
import React, { Fragment, useContext, useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Paths } from "routes";
import { getUser, updateCompanyName } from "services/Auth";
import AddCallsDialog from "dialogs/addCallsDialog";
import SubscriptionPlanDialog from "dialogs/subscriptionDialog";

export default function MyAccount({ auth }) {
  const navigate = useNavigate();
  const [changePassword, setChangePassword] = useState(false);
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [loading, setLoading] = useState("");
  const [infoDialogDetail, setInfoDialogDetail] = useState(false);
  const [addCallsDialog, setAddCallsDialog] = useState(false);
  const [subscriptionPopup, setSubscriptionPopup] = useState(false);

  useEffect(() => {
    setCompanyName(auth?.userData?.UserData?.companyName);
  }, [auth?.userData?.UserData?.companyName]);

  const { changeAuth } = useContext(AuthContext);

  const handleUpdateCompanyName = async () => {
    setLoading("update_company");
    const resp = await updateCompanyName({ CompanyName: companyName });
    if (resp.status === 200) {
      let res = await getUser();
      changeAuth({
        isLoggedIn: true,
        userData: { ...auth.userData, ...res.data },
      });
      setInfoDialogDetail(true);
      setIsEditMode(false);
    }
    setLoading("");
  };
  return (
    <div class="xl:py-10 py-8 xl:px-12 px-10 h-[calc(100%_-_75px)]">
      {infoDialogDetail && (
        <InfoDialog
          open={true}
          setOpen={() => setInfoDialogDetail(false)}
          infoDetail={{
            heading: "Changes Saved!",
          }}
          handleSubmit={() => {}}
          icon={CompletedGreen}
        />
      )}
      <VerifyDeleteAccount open={deleteAccount} setOpen={setDeleteAccount} />
      <ChangePassword
        open={changePassword}
        setOpen={setChangePassword}
        isUpdatePassword={true}
      />
      {addCallsDialog && (
        <AddCallsDialog open={addCallsDialog} setOpen={setAddCallsDialog} />
      )}
      {subscriptionPopup && (
        <SubscriptionPlanDialog
          open={subscriptionPopup}
          setOpen={setSubscriptionPopup}
        />
      )}
      <div className="h-full bg-[#FDFEFF] border border-[#E5E5E5] rounded-xl overflow-hidden px-8 py-7">
        <div class="flex justify-between items-center mb-3">
          <button
            class="flex items-center gap-1 text-[#121212] text-sm font-semibold leading-[19px]"
            onClick={() => navigate(-1)}
          >
            <img src={ArrowBackIcon} alt="back-arrow" />
            Back
          </button>
        </div>
        <div class="flex justify-between items-center mb-6 mt-3">
          <h5 className="text-[28px] leading-9 font-medium text-[#4A4A4A] ">
            My Account
          </h5>

          {isEditMode ? (
            <div className="flex gap-2">
              <input
                type="text"
                className="w-full text-[#1A1A1A]  text-sm font-medium leading-5 py-[1px] px-2 border border-[#e5e5e5] rounded-md focus:outline-none"
                value={companyName}
                onChange={(event) => setCompanyName(event.target.value)}
              />
              <div className="flex gap-1">
                <button
                  type="button"
                  className={`whitespace-nowrap btn bg-white h-[25px] flex items-center justify-center border-custom-primary border-[1px] border-purple font-medium text-[13px] leading-[16px] text-[#121212] py-[10px] px-[16px] rounded-lg`}
                  onClick={() => {
                    setIsEditMode(false);
                    setCompanyName(auth?.userData?.UserData?.companyName);
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={`whitespace-nowrap btn bg-custom-primary h-[25px] flex items-center justify-center border-custom-primary border-[1px] border-purple font-medium text-[13px] leading-[16px] text-[#fff] py-[10px] px-[16px] rounded-lg disabled:opacity-75`}
                  onClick={() => handleUpdateCompanyName()}
                  disabled={loading === "update_company"}
                >
                  {loading === "update_company" ? "Updating..." : "Update"}
                </button>
              </div>
            </div>
          ) : (
            <div className="flex gap-1">
              <h6 className="text-xl font-medium text-[#1A1A1A] leading-[25px] -tracking-[0.2px] m-0">
                {companyName}
              </h6>
              <img
                src={EditIcon}
                onClick={() => setIsEditMode(true)}
                className="min-w-5 min-h-5 cursor-pointer"
                alt="edit-icon"
              />
            </div>
          )}
        </div>
        <div className="h-[calc(100%_-_94px)] overflow-auto scrollbar">
          <div className="flex gap-6">
            <div className="w-full h-full max-w-[392px] flex flex-col">
              <div
                onClick={() => navigate("/my-account")}
                className="flex items-center gap-2 p-4 border border-[#e5e5e5] rounded-md mb-6"
              >
                <div className="w-[52px] h-[52px]">
                  <Avatar
                    sx={{ bgcolor: "#2764D2", border: "2px solid #061025" }}
                  >
                    {auth.userData?.FirstName?.charAt(0) || ""}
                  </Avatar>
                </div>
                <div class="flex flex-col">
                  <h6 className="text-xl font-medium text-[#1A1A1A] leading-[25px] -tracking-[0.2px] m-0">
                    {auth.userData?.FirstName || ""}{" "}
                    {auth.userData?.LastName || ""}
                  </h6>
                  <span className="text-sm font-light text-[#1A1A1A] leading-5">
                    {auth.userData?.Email || ""}
                  </span>
                </div>
              </div>
              <div className="h-full flex flex-col justify-between items-start">
                <div className="flex flex-col border border-[#e5e5e5] rounded-md w-full">
                  <button
                    onClick={() => setChangePassword(true)}
                    className="py-3 px-4 text-sm font-medium leading-5 -tracking-[0.21px] text-[#353535] border-b border-[#e5e5e5]"
                  >
                    <div className="flex items-center">
                      <img
                        src={KeyIcon}
                        alt="change-password"
                        className="mr-2"
                      />
                      Change Password
                    </div>
                  </button>
                  <button
                    className="py-3 px-4 text-sm font-medium leading-5 -tracking-[0.21px] text-[#353535] border-b border-[#e5e5e5]]"
                    onClick={() => setSubscriptionPopup(true)}
                  >
                    <div className="flex items-center">
                      <img
                        src={CreditCardIcon}
                        alt="manage-billing"
                        className="mr-2"
                      />
                      Manage Billing
                    </div>
                  </button>
                  <button
                    onClick={() => navigate("/contact-us")}
                    className="py-3 px-4 text-sm font-medium leading-5 -tracking-[0.21px] text-[#353535] border-b border-[#e5e5e5]]"
                  >
                    <div className="flex items-center">
                      <img
                        src={ContactUsIcon}
                        alt="contact-us"
                        className="mr-2"
                      />
                      Contact us
                    </div>
                  </button>
                  <button
                    className="py-3 px-4 text-sm font-medium leading-5 -tracking-[0.21px] text-[#353535] border-b border-[#e5e5e5]"
                    onClick={() =>
                      window.open(
                        "https://app.termly.io/policy-viewer/policy.html?policyUUID=c9e5e90a-fadc-424c-942f-13f8d14b92e7",
                        "_blank"
                      )
                    }
                  >
                    <div className="flex items-center">
                      <img
                        src={TermsOfServiceIcon}
                        alt="terms-of-service"
                        className="mr-2"
                      />
                      Terms of Use
                    </div>
                  </button>
                  <button
                    className="py-3 px-4 text-sm font-medium leading-5 -tracking-[0.21px] text-[#353535] border-b border-[#e5e5e5]"
                    onClick={() =>
                      window.open(
                        "https://app.termly.io/policy-viewer/policy.html?policyUUID=0b67ab5b-10ce-4dfc-b0c9-e289d9c54cdb",
                        "_blank"
                      )
                    }
                  >
                    <div className="flex items-center">
                      <img
                        src={TermsOfServiceIcon}
                        alt="privacy-policy"
                        className="mr-2"
                      />
                      Privacy Policy
                    </div>
                  </button>
                  <button
                    onClick={() => {
                      localStorage.removeItem("token");
                      localStorage.removeItem("AuthContext");
                      changeAuth({ isLoggedIn: false, userData: {} });
                      navigate(Paths.SignUp);
                    }}
                    className="py-3 px-4 text-sm font-medium leading-5 -tracking-[0.21px] text-[#353535]"
                  >
                    <div className="flex items-center">
                      <img src={LogoutIcon} alt="logout" className="mr-2" />
                      Log out
                    </div>
                  </button>
                </div>
                <button
                  className="text-sm font-medium leading-5 -tracking-[0.21px] text-[#F83A3A] mt-8"
                  onClick={() => setDeleteAccount(true)}
                >
                  Delete Account
                </button>
              </div>
            </div>
            <div className="border border-[#e5e5e5] rounded-md px-8 pt-4 w-full">
              <h5 className="text-xl leading-[25px] -tracking-[0.2px] font-medium text-[#1A1A1A] mb-6 mt-3">
                Subscription Plan
              </h5>
              <p className="text-[13px] text-[#1f1f1f] font-light leading-5 -tracking-[0.13px] pb-4 border-b border-[#E5E5E5]">
                Your subscription is{" "}
                <span className="font-bold">
                  {_.get(auth, "userData.Subscription[0].status", "") ===
                  "active"
                    ? "Active"
                    : "In Active"}
                  .
                </span>{" "}
                {_.get(auth, "userData.Subscription[0].status", "") ===
                "active" ? (
                  <Fragment>
                    To manage your account please{" "}
                    <span
                      href="#"
                      className="text-[#2764D2] font-medium cursor-pointer"
                      onClick={() => setSubscriptionPopup(true)}
                    >
                      Click here
                    </span>
                    .
                    {_.get(auth, "userData.Subscription[0].lastInvoiceDate", "")
                      ? `We'll automatically renew your subscription on ${new Date(
                          new Date(
                            _.get(
                              auth,
                              "userData.Subscription[0].lastInvoiceDate",
                              ""
                            )
                          ).setMonth(
                            new Date(
                              _.get(
                                auth,
                                "userData.Subscription[0].lastInvoiceDate",
                                ""
                              )
                            ).getMonth() + 1
                          )
                        ).toLocaleDateString("en-GB", {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        })}
                        .`
                      : ""}{" "}
                    If you want to have more calls click on{" "}
                    <span
                      href="#"
                      className="text-[#2764D2] font-medium cursor-pointer"
                      onClick={() => setAddCallsDialog(true)}
                    >
                      Add more calls
                    </span>
                    .
                  </Fragment>
                ) : (
                  "Upgrade your plan to continue enjoying additional features and calls"
                )}
              </p>
              {_.get(auth, "userData.Subscription[0].status", "") ===
                "active" && (
                <Fragment>
                  <h6 className="mt-6 block text-lg text-[#2764D2] font-medium leading-[22px] -tracking-[0.18px]">
                    {_.get(auth, "userData.Subscription[0].monthlyCredit", 0)}{" "}
                    AI Calls <span className="Text-[#353535]">every month</span>
                  </h6>
                  <span className="text-[13px] text-[#1F1F1F] font-light leading-[21px] block mb-6">
                    {new Date(
                      _.get(
                        auth,
                        "userData.Subscription[0].lastInvoiceDate",
                        ""
                      )
                    ).toLocaleDateString("en-GB", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}{" "}
                    →{" "}
                    {new Date(
                      new Date(
                        _.get(
                          auth,
                          "userData.Subscription[0].lastInvoiceDate",
                          ""
                        )
                      ).setMonth(
                        new Date(
                          _.get(
                            auth,
                            "userData.Subscription[0].lastInvoiceDate",
                            ""
                          )
                        ).getMonth() + 1
                      )
                    ).toLocaleDateString("en-GB", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })}{" "}
                  </span>
                  <div className="flex flex-col gap-1">
                    <span className="text-[#353535] text-[15px] font-semibold leading-5">
                      {_.get(auth, "userData.UserData.credits", 0) >
                      _.get(auth, "userData.Subscription[0].monthlyCredit", 0)
                        ? _.get(
                            auth,
                            "userData.Subscription[0].monthlyCredit",
                            0
                          )
                        : _.get(auth, "userData.UserData.credits", 0)}
                      /
                      {_.get(auth, "userData.Subscription[0].monthlyCredit", 0)}
                    </span>
                    {/* <div className={`w-full h-[16px] rounded-[15px] bg-[#F6F9FD] relative after:content-[''] after:absolute after:inset-0 after:bg-[#2764D2] after:w-[20%] after:rounded-[15px]`}></div> */}
                    <div className="w-full h-[16px] rounded-[15px] bg-[#F6F9FD] relative">
                      <div
                        className="absolute inset-0 bg-[#2764D2] rounded-[15px]"
                        style={{
                          width: `${Math.min(
                            Math.round(
                              (_.get(auth, "userData.UserData.credits", 0) /
                                _.get(
                                  auth,
                                  "userData.Subscription[0].monthlyCredit",
                                  0
                                )) *
                                100
                            ),
                            100
                          )}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
