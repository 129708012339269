import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import { EditIcon, ArrowDown } from "assets";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F2F6FC",
    zIndex:"1000000",
    padding: "12px 24px",
    minHeight: "40px",
    border: "none",
    color: "#353535",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    position: "relative",
    textAlign: "center",

    "&:first-child": {
      padding: "12px 20px",
      width: "10%",
      "&:after": {
        // content: "none",
      },
    },
    "&:last-child": {
        "&:after": {
          content: "none",
        },
      },
    "&:nth-child(2)": {
      width: "20%",
    },
    "&:nth-child(3)": {
      width: "15%",
    },
    "&:nth-child(4)": {
      width: "20%",
    },
    "&:nth-child(5)": {
      width: "20%",
    },
    "&:nth-child(6)": {
      width: "20%",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      right: "0px",
      width: "1px",
      height: "24px",
      backgroundColor: "#2764d233",
      transform: "translateY(-50%)",
    },
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#353535",
    fontSize: "13px",
    fontWeight: "400",
    lineHeight: "18px",
    padding: "10px 0px",
    textAlign: "center",
    height: "52px"

  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  //   '&:nth-of-type(odd)': {
  //     backgroundColor: theme.palette.action.hover,
  //   },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:hover": {
    backgroundColor: "#f7f7f7"
  }
}));

export default function CustomizedTable({
  columns,
  children,
  isHeaderCheckbox,
  totalCount,
  currentPage,
  handlePageChange,
  selectedCandidates,
  isShowPagination = true
}) {
  return (
    <div className="flex flex-col h-full">
      {/* <div className="flex-grow overflow-hidden"> */}
      <TableContainer className="h-full w-full scrollbar">
        <Table
          // className="h-full"
          sx={{
            minWidth: 700,
            // tableLayout: "fixed",
          }}
          aria-label="customized table"
          stickyHeader
        >
          <TableHead className="bg-[#2764d20d] rounded sticky top-0 z-10">
            <TableRow>
                {/* {isHeaderCheckbox && <StyledTableCell align="center"  >
                  <img src={EditIcon} className="min-w-5 min-h-5 mx-auto" alt="edit-icon" />
                </StyledTableCell>} */}
              {columns.map((col, index) => (
                <StyledTableCell key={index} className="whitespace-nowrap">
                  {col.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="overflow-y-auto scrollbar">
            {children}
          </TableBody>
        </Table>
      </TableContainer>
      {/* </div> */}
      {isShowPagination && <div className="flex justify-end items-center p-4 bg-white">
        {/* <span className="w-full max-w-[148px] cursor-pointer flex justify-between items-center text-[#121212] text-[13px] leading-[16px] font-medium py-3 px-[14px] rounded-md border border-[#E2E2E2]">
          Selected: {selectedCandidates.length}
          <img src={ArrowDown} alt="down-arrow" />
        </span> */}
        <Pagination
          count={Math.ceil(totalCount / 10) || 1}
          page={currentPage || 1}
          onChange={handlePageChange}
          shape="rounded"
          color="primary"
        />
      </div>}
    </div>
  );
}
