import { forwardRef, useEffect, useState } from "react";
import { Dialog, DialogContent, DialogContentText, Slide } from "@mui/material";
import { CompletedGreen, CopyIcon, CrossIcon, WarningInfoIcon } from "assets";
import { useNotification } from "contexts/notificationContext";
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ContactCandidate = (props) => {
  const { open, setOpen, selectedCandidate } = props;
  const { showNotification } = useNotification();
  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        showNotification("Copied successfully!", "success");
      })
      .catch((err) => {
        showNotification("Failed to copy!", "error");
      });
  }  

  const renderText = (label, text) => {
    return (
      <div className="flex justify-between py-4 border-b-[1px] border-[#E5E5E5]">
        <p className="text-[#1F1F1F] opacity-60 text-[16px] leading-4 font-normal">{label}</p>
        <div className="flex gap-[6px]">
          <p className="text-[#393939] font-semibold text-[15px] leading-5">{text || "N/A"}</p>
          <img src={CopyIcon} alt="copyIcon" width={16} height={16} className="cursor-pointer" onClick={() => handleCopy(text || "N/A")}/>
        </div>
      </div>
    )
  }
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        PaperProps={{
          style: {
            maxWidth: "584px",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent className="!pt-[52px] !pb-[64px] !px-[80px] relative">
          <DialogContentText id="alert-dialog-slide-description">
            <img
              src={CrossIcon}
              alt="crossIcon"
              className="ml-auto cursor-pointer absolute top-4 right-4"
              onClick={handleClose}
            />
            <div className="flex flex-col justify-start items-start gap-11 max-w-[424px] w-full mx-auto font-hankengrotesk">
              <div className="flex flex-col gap-7">
                <div className="flex flex-col gap-2">
                  <h6 className="font-semibold text-[28px] leading-9 text-[#353535] m-0">
                    Contact Candidate
                  </h6>
                  <p className="text-[15px] text-[#1F1F1F] leading-[22px]  -tracking-[0.15px] font-light m-0">
                    Here are the details you need to contact this person. Note
                    we do not currently support calls outside the AI agent
                  </p>
                </div>
                <div>
                    {renderText("Email Address", selectedCandidate?.Email)}
                    {renderText("Phone Number", selectedCandidate?.phoneNumber)}
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ContactCandidate;
